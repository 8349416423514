import type { AxShareIdentityProvider, AxShareSubInstanceInfo, ResponseObject, SetAxureRpRoleResponse, SubInstanceOptions, UserAxureRpRole, UserType } from "@/generated/models";
import type { RequestOptions } from "../common/server";
import { objectToFormData } from "@/services/utils/formData";
import { server } from "./server";

export async function getSubInstanceInfo({ organizationId }: { organizationId: string }, { signal }: RequestOptions = {}) {
  const response = await server.get("admin/getSubInstanceInfo", {
    params: {
      organizationId,
    },
    signal,
  });
  return response.data as AxShareSubInstanceInfo;
}

export interface SetUserAccessLevelModel {
  userIds: string;
  organizationId: string;
  accessLevel: number;
}

export async function setAccessLevel(options: { userIds: string[]; organizationId: string; accessLevel: UserType }) {
  const model: SetUserAccessLevelModel = {
    userIds: options.userIds.join(","),
    organizationId: options.organizationId,
    accessLevel: options.accessLevel,
  };

  const formData = objectToFormData(model);
  const response = await server.post("admin/setAccessLevel", formData);
  return response.data as ResponseObject;
}

export interface SetUserAxureRpRoleModel {
  userIds: string;
  axureRpRole: number;
  assignSeats: boolean;
}

export async function setAxureRpRole(options: { userIds: string[]; axureRpRole: UserAxureRpRole; assignSeats: boolean }) {
  const model: SetUserAxureRpRoleModel = {
    userIds: options.userIds.join(","),
    axureRpRole: options.axureRpRole,
    assignSeats: options.assignSeats,
  };

  const formData = objectToFormData(model);
  const response = await server.post("admin/setAxureRpRole", formData);
  return response.data as SetAxureRpRoleResponse;
}

export interface ChangeUserSeatStatusModel {
  userToChangeId: string;
  assignSeat: boolean;
}

export async function changeUserSeatStatus(options: { userId: string; assignSeat: boolean }) {
  const model: ChangeUserSeatStatusModel = {
    userToChangeId: options.userId,
    assignSeat: options.assignSeat,
  };

  const formData = objectToFormData(model);
  const response = await server.post("admin/changeUserSeatStatus", formData);
  return response.data as ResponseObject;
}
export interface ChangeUserEmailModel {
  targetUserId: string;
  email: string;
}

export async function setEmail(options: { userId: string; email: string }) {
  const model: ChangeUserEmailModel = {
    targetUserId: options.userId,
    email: options.email,
  };

  const formData = objectToFormData(model);
  const response = await server.post("admin/setEmail", formData);
  return response.data as ResponseObject;
}
export interface SetUserPasswordModel {
  targetUserId: string;
  password: string;
  pending: boolean;
}

export async function setPassword(options: { userId: string; password: string; requirePasswordChange: boolean }) {
  const model: SetUserPasswordModel = {
    targetUserId: options.userId,
    password: options.password,
    pending: options.requirePasswordChange,
  };

  const formData = objectToFormData(model);
  const response = await server.post("admin/setPassword", formData);
  return response.data as ResponseObject;
}

export interface ChangeUserStateModel {
  userIds: string;
  active: boolean;
}

export async function changeUserState(options: { userIds: string[]; active: boolean }) {
  const model: ChangeUserStateModel = {
    userIds: options.userIds.join(","),
    active: options.active,
  };

  const formData = objectToFormData(model);
  const response = await server.post("admin/changeUserState", formData);
  return response.data as ResponseObject;
}

export interface ChangeUserAuthenticationModel {
  userIds: string;
}

export async function addToSaml(options: { userIds: string[] }) {
  const model: ChangeUserAuthenticationModel = {
    userIds: options.userIds.join(","),
  };

  const formData = objectToFormData(model);
  const response = await server.post("admin/addToSaml", formData);
  return response.data as ResponseObject;
}

export interface ChangeUserAuthenticationModel {
  userIds: string;
}

export async function removeFromSaml(options: { userIds: string[] }) {
  const model: ChangeUserAuthenticationModel = {
    userIds: options.userIds.join(","),
  };

  const formData = objectToFormData(model);
  const response = await server.post("admin/removeFromSaml", formData);
  return response.data as ResponseObject;
}

export interface ChangeSeatsBulkModel {
  userIds: string;
  assignSeats: boolean;
}

export async function changeUserSeatStatusBulk(options: { userIds: string[]; assignSeats: boolean }) {
  const model: ChangeSeatsBulkModel = {
    userIds: options.userIds.join(","),
    assignSeats: options.assignSeats,
  };

  const formData = objectToFormData(model);
  const response = await server.post("admin/assignSeatsBulk", formData);
  return response.data as ResponseObject;
}

export interface AddUsersModel {
  userEmails: string;
  useSaml: boolean;
  accessLevel: number;
  axureRpRole: number;
  pending: boolean;
  assignSeats: boolean;
}

export async function addAccounts(options: { userEmails: string[]; useSaml: boolean; systemRole: UserType; axureRpRole: UserAxureRpRole }) {
  const model: AddUsersModel = {
    userEmails: options.userEmails.join(","),
    useSaml: options.useSaml,
    accessLevel: options.systemRole,
    axureRpRole: options.axureRpRole,
    pending: true,
    assignSeats: true,
  };

  const formData = objectToFormData(model);
  const response = await server.post("admin/addAccounts", formData);
  return response.data as ResponseObject;
}

export async function setSubInstanceOptions(options: SubInstanceOptions) {
  const formData = objectToFormData(options);
  const response = await server.post("admin/setSubInstanceOptions", formData);
  return response.data as ResponseObject;
}

export async function getSamlSettings({ signal }: RequestOptions = {}) {
  const response = await server.get("admin/getSamlSettings", {
    signal,
  });
  return response.data as AxShareIdentityProvider;
}

export async function clearSaml() {
  const response = await server.post("admin/clearSaml");
  return response.data as ResponseObject;
}

import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  width: "16",
  height: "16",
  fill: "none",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<rect width=\"16\" height=\"16\" fill=\"#E2E8F0\" rx=\"4\"></rect><g clip-path=\"url(#0ffb7efc__a)\"><path stroke=\"#1E293B\" stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"m5.743 4.267.305 1.104M4.943 6.476l-1.105-.305m4.495-1.18-.762.723M5.286 8l-.724.762m1.867-1.905 1.904 4.572.686-1.981L11 8.762z\"></path></g><defs><clipPath id=\"0ffb7efc__a\"><path fill=\"#fff\" d=\"M3 3.429h9.143v9.143H3z\"></path></clipPath></defs>", 3)
  ])))
}
export default { render: render }
import type { RefOrGetter } from "ls/common/types";
import { useQuery } from "@tanstack/vue-query";
import { useIsSubInstance } from "ls/state/useSubInstance";
import { computed, toValue } from "vue";
import { queries } from "./queries";

export function useOrganizationAdminInfo(organizationId: RefOrGetter<string>) {
  const isSubInstance = useIsSubInstance();
  const options = computed(() => {
    return {
      ...queries.adminInfo(toValue(organizationId)),
      enabled: isSubInstance.value,
    };
  });
  const query = useQuery(options);

  return query.data;
}

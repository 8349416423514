import type { ListenersMap } from "./types";
import { onBeforeMount, onBeforeUnmount } from "vue";
import { type HubName, useAxureCloudHubsConnection } from "./useAxureCloudHubsConnection";

export interface UseAxureCloudHubOptions<TPayload> {
  listeners?: ListenersMap<TPayload>;
}

export function useAxureCloudHub<TPayload>(name: HubName, options: UseAxureCloudHubOptions<TPayload> = {}) {
  const connection = useAxureCloudHubsConnection();

  async function joinGroup(groupName: string) {
    await connection.addToGroup(name, groupName);
  }

  async function leaveGroup(groupName: string) {
    await connection.removeFromGroup(name, groupName);
  }

  onBeforeMount(async () => {
    await connection.connectToHub(name);
    if (options.listeners) {
      await connection.addHubListeners(name, options.listeners);
    }
  });

  onBeforeUnmount(async () => {
    if (options.listeners) {
      await connection.removeHubListeners(name, options.listeners);
    }
  });

  return {
    joinGroup,
    leaveGroup,
  };
}

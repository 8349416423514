<script setup lang="ts">
import { ComboboxLabel, type ComboboxLabelProps, useForwardProps } from "radix-vue";

const props = defineProps<ComboboxLabelProps>();
const forwardedProps = useForwardProps(props);
</script>

<template>
  <ComboboxLabel
    v-bind="forwardedProps"
    class="my-1 px-2 text-xs font-medium text-slate-500"
  >
    <slot />
  </ComboboxLabel>
</template>

<script setup lang="ts">
import { useAxureCloudConfig } from "ls/state/useAxureCloudConfig";
import { computed } from "vue";
import SimpleBanner from "./SimpleBanner.vue";

const config = useAxureCloudConfig();
const readOnlyMessage = computed(() => config?.value?.ReadOnlyMessage ?? "Axure Cloud is in read-only mode");
</script>

<template>
  <SimpleBanner v-if="config && config.ReadOnlyMode">
    {{ readOnlyMessage }}
  </SimpleBanner>
</template>

import type { AxShareConfigModel, ShortcutJs } from "@/generated/models";
import type { RefOrGetter } from "ls/common/types";
import { resolveApiBaseUrl } from "@/common/axshare/api";
import { useAxureCloudConfig } from "ls/state/useAxureCloudConfig";
import { joinURL, withProtocol } from "ufo";
import { computed, toValue } from "vue";
import { isRpProject, isTeamProject, isThumbnailSupportedProject } from "./utils";

export const accessCodeParamName = "code";

function useFramedDomain(file: ShortcutJs, config: AxShareConfigModel | null): config is AxShareConfigModel {
  return !!config && !!config.AxSitesFramedPrototypeDomain && isThumbnailSupportedProject(file);
}

function teamDownloadUrl(shortcut: string, baseUrl: string, revision?: number) {
  const url = new URL("versions/DownloadRevision", baseUrl);
  url.searchParams.append("shortcut", shortcut);
  if (revision !== undefined) {
    url.searchParams.append("revNumber", revision.toString());
  }
  return url.href;
}

function rpDownloadUrl(shortcut: string, baseUrl: string) {
  const url = new URL(`prototype/DownloadRpFile/${shortcut}`, baseUrl);
  return url.href;
}

function downloadUrl(file: ShortcutJs, baseUrl: string) {
  const { Shortcut: shortcut } = file;

  // Download file is only supported for RP/Lib projects and Team projects
  return isTeamProject(file)
    ? teamDownloadUrl(shortcut, baseUrl)
    : isRpProject(file)
      ? rpDownloadUrl(shortcut, baseUrl)
      : undefined;
}

export function getTeamRpDownloadUrl(file: ShortcutJs, config: AxShareConfigModel, revision?: number) {
  const baseUrl = resolveApiBaseUrl(config);
  return teamDownloadUrl(file.Shortcut, baseUrl, revision);
}

export function getFileLinks(file: ShortcutJs, config: AxShareConfigModel) {
  const { PrototypeUrl: prototypeUrl, Shortcut: shortcut } = file;

  let accessCode: string | null = file.AccessCode;
  if (!accessCode) {
    // If user has no access to project info then let's check current URL and use access code from URL params if such exists
    const currentUrl = new URL(window.location.href);
    accessCode = currentUrl.searchParams.get(accessCodeParamName);
  }

  const baseUrl = resolveApiBaseUrl(config);

  const full = prototypeUrl !== ""
    ? prototypeUrl.toLowerCase()
    : joinURL(baseUrl, shortcut);

  let frame = full;
  if (useFramedDomain(file, config)) {
    const protocol = config.MatchProtocolMode ? "http://" : "https://";
    const url = withProtocol(`${shortcut}${config.AxSitesFramedPrototypeDomain}`, protocol);
    const frameUrl = new URL(url);
    if (import.meta.env.DEV) {
      frameUrl.port = "44300";
    }
    frame = frameUrl.href;
  }

  const shareUrl = new URL(full);
  if (accessCode) {
    shareUrl.searchParams.append(accessCodeParamName, accessCode);
  }

  return {
    full,
    frame,
    share: shareUrl.href,
    download: downloadUrl(file, baseUrl),
  };
}

export function useFileLinks(file: RefOrGetter<ShortcutJs>) {
  const config = useAxureCloudConfig();
  const links = computed(() => {
    if (!config.value) {
      throw new Error("Config is required to construct file urls.");
    }

    return getFileLinks(toValue(file), config.value);
  });

  return links;
}

const toString = Object.prototype.toString;

export function isArrayBuffer(val: any): val is ArrayBuffer {
  return toString.call(val) === "[object ArrayBuffer]";
}

export function isFormData(val: any): val is FormData {
  return typeof FormData !== "undefined" && val instanceof FormData;
}

export function isArrayBufferView(val: any): val is ArrayBufferView {
  let result: boolean;
  if (typeof ArrayBuffer !== "undefined" && ArrayBuffer.isView) {
    result = ArrayBuffer.isView(val);
  } else {
    result = val && val.buffer && val.buffer instanceof ArrayBuffer;
  }
  return result;
}

export function isFile(val: any): val is File {
  return toString.call(val) === "[object File]";
}

export function isBlob(val: any): val is Blob {
  return toString.call(val) === "[object Blob]";
}

// eslint-disable-next-line ts/no-unsafe-function-type
export const isFunction = (val: unknown): val is Function => typeof val === "function";
export const isString = (val: unknown): val is string => typeof val === "string";
export const isObject = (val: unknown): val is Record<any, any> => val !== null && typeof val === "object";

export const isPromise = <T = any>(val: unknown): val is Promise<T> => isObject(val) && isFunction(val.then) && isFunction(val.catch);

export function isURLSearchParams(val: any): val is URLSearchParams {
  return typeof URLSearchParams !== "undefined" && val instanceof URLSearchParams;
}

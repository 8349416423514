import { retryUntilTruthy } from "@/common/lib/promise";
import { HttpTransportType, type HubConnection, HubConnectionBuilder, HubConnectionState, type IRetryPolicy, LogLevel } from "@microsoft/signalr";
import { createSharedComposable } from "@vueuse/core";
import { secondsToMilliseconds } from "date-fns";
import { cloudApiUrl } from "ls/api/urls";
import { useAxureCloudConfig } from "ls/state/useAxureCloudConfig";
import { joinURL } from "ufo";
import { computed } from "vue";

export type SignalRHubListenerOf = (data: any) => unknown;
export type SignalRHubListeners = Record<string, SignalRHubListenerOf>;

export type HubName = "notifications" | "workspaces" | "projects" | "feeds" | "organizations";

function randomIntegerBetween(min: number, max: number) {
  const rMin = Math.ceil(min);
  const rMax = Math.floor(max);
  return Math.floor(Math.random() * (rMax - rMin)) + rMin;
}

// intervals in seconds
const intervals = [
  [0, 10],
  [20, 40],
  [60, 90],
  [120, 180],
] as const;

function retryPolicyFactory(): IRetryPolicy {
  const randomizedIntervals = intervals.map(([from, to]) => randomIntegerBetween(secondsToMilliseconds(from), secondsToMilliseconds(to)));
  const retryIntervals = [...randomizedIntervals, null];

  return {
    nextRetryDelayInMilliseconds(retryContext) {
      return retryIntervals[retryContext.previousRetryCount] ?? null;
    },
  };
}

interface HubConnectionMetadata {
  hasOnReconnectedHandler: boolean;
}

function useAxureCloudHubsConnectionFn() {
  const hubConnections = new Map<HubName, HubConnection>();
  const hubConnectionMetadata = new WeakMap<HubConnection, HubConnectionMetadata>();
  const activeHubListeners = new WeakMap<HubConnection, Set<SignalRHubListenerOf>>();
  const groupsMembership = new WeakMap<HubConnection, Set<string>>();

  const config = useAxureCloudConfig();
  const enabled = computed(() => !!config.value?.EnablePushNotifications);

  async function start(connection: HubConnection | undefined): Promise<HubConnection | undefined> {
    // giving some time until connection is fully ready
    const retries = 10;
    const timeout = 500;
    return retryUntilTruthy(
      () => {
        if (connection && connection.state === HubConnectionState.Connected) {
          return connection;
        }
        return undefined;
      },
      retries,
      timeout,
    );
  }

  async function connectToHub(hubName: HubName) {
    await ensureHubStarted(hubName);
  }

  async function disconnectFromHub(hubName: HubName) {
    const connection = hubConnections.get(hubName);
    if (connection) {
      if (connection.state === HubConnectionState.Connecting) {
        // tried to immediately close connection
        // give it little timeout to finish connecting and then close connection
        await start(connection);
      }
      if (connection.state === HubConnectionState.Connected) {
        await connection.stop();
      }
      hubConnections.delete(hubName);
    }
  }

  async function disconnect() {
    const disconnectFromHubTasks = [];
    for (const hubName of hubConnections.keys()) {
      disconnectFromHubTasks.push(disconnectFromHub(hubName));
    }
    await Promise.all(disconnectFromHubTasks);
  }

  function getConnectionMetadata(connection: HubConnection) {
    let metadata = hubConnectionMetadata.get(connection);
    if (metadata) return metadata;

    metadata = {
      hasOnReconnectedHandler: false,
    };
    hubConnectionMetadata.set(connection, metadata);
    return metadata;
  }

  async function addToGroupForConnection(connection: HubConnection, hubName: HubName, groupName: string) {
    const groupNameNormalized = groupName.toLowerCase();
    await connection.send("AddToGroup", groupNameNormalized);

    let groups = groupsMembership.get(connection);
    if (!groups) {
      groups = new Set();
      groupsMembership.set(connection, groups);
    }
    groups.add(groupNameNormalized);
  }

  async function restoreGroupsMembership(
    hubName: HubName,
    connectionId: string | undefined,
    connection: HubConnection,
  ): Promise<void> {
    if (connectionId && connection.state === HubConnectionState.Connected) {
      const groups = groupsMembership.get(connection);
      if (!groups) return;
      for (const group of groups) {
        await addToGroupForConnection(connection, hubName, group);
      }
    }
  }

  async function ensureHubStarted(hubName: HubName): Promise<HubConnection | undefined> {
    if (!enabled.value) return undefined;

    let newConnection = hubConnections.get(hubName);

    if (!newConnection) {
      const hubUrl = joinURL(cloudApiUrl, "hubs", hubName);
      newConnection = new HubConnectionBuilder()
        .withUrl(hubUrl, {
          transport: HttpTransportType.WebSockets,
          skipNegotiation: true,
        })
        .withAutomaticReconnect(retryPolicyFactory())
        .configureLogging(import.meta.env.DEV ? LogLevel.Information : LogLevel.Warning)
        .build();
      hubConnections.set(hubName, newConnection);
      activeHubListeners.set(newConnection, new Set<SignalRHubListenerOf>());
    }

    if (newConnection.state === HubConnectionState.Disconnected) {
      try {
        await newConnection.start();
      } catch {
        // failed to connect to SignalR hub
        // it's possible that server is actively refusing new connections
        // in that case we're handling this gracefully, as if push notifications are disabled completely
        return;
      }
    }

    const connectionStarted = await start(newConnection);
    if (connectionStarted) {
      const metadata = getConnectionMetadata(connectionStarted);
      if (!metadata.hasOnReconnectedHandler) {
        newConnection.onreconnected(connectionId => {
          void restoreGroupsMembership(hubName, connectionId, connectionStarted);
        });
        metadata.hasOnReconnectedHandler = true;
      }
      return connectionStarted;
    }

    return undefined;
  }

  async function addToGroup(hubName: HubName, groupName: string) {
    const connection = await ensureHubStarted(hubName);
    if (!connection) return;
    return addToGroupForConnection(connection, hubName, groupName);
  }

  async function removeFromGroup(hubName: HubName, groupName: string) {
    const groupNameNormalized = groupName.toLowerCase();
    // don't have to ensure that hub's connected
    // when client disconnects its group membership will be revoked
    const connection = hubConnections.get(hubName);

    if (connection && connection.state === HubConnectionState.Connected) {
      await connection.send("RemoveFromGroup", groupNameNormalized);

      const groups = groupsMembership.get(connection);
      if (groups) {
        groups.delete(groupNameNormalized);
      }
    }
  }

  async function addHubListeners(hubName: HubName, listeners: SignalRHubListeners) {
    const connection = await ensureHubStarted(hubName);
    if (connection) {
      for (const methodName in listeners) {
        if (Object.prototype.hasOwnProperty.call(listeners, methodName)) {
          const listener = listeners[methodName];
          if (!listener) continue;

          connection.on(methodName, listener);
          const hubListeners = activeHubListeners.get(connection);
          if (hubListeners) {
            hubListeners.add(listener);
          }
        }
      }
    }
  }

  async function removeHubListeners(hubName: HubName, listeners: SignalRHubListeners) {
    const connection = hubConnections.get(hubName);
    if (connection) {
      for (const methodName in listeners) {
        if (Object.prototype.hasOwnProperty.call(listeners, methodName)) {
          const listener = listeners[methodName];
          if (!listener) continue;

          connection.off(methodName, listener);
          const hubListeners = activeHubListeners.get(connection);
          if (hubListeners) {
            hubListeners.delete(listener);
          }
        }
      }

      const hubListeners = activeHubListeners.get(connection);
      if (!hubListeners || hubListeners.size === 0) {
        await disconnectFromHub(hubName);
      }
    }
  }

  return {
    connectToHub,
    disconnect,
    addHubListeners,
    removeHubListeners,
    addToGroup,
    removeFromGroup,
  };
}

export const useAxureCloudHubsConnection = createSharedComposable(useAxureCloudHubsConnectionFn);

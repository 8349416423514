import { queryOptions } from "@tanstack/vue-query";
import { getFolderNavByShortcutId } from "ls/api/cloud/fs";
import { getPassword, getPrototypeInfo, getPrototypePages } from "ls/api/cloud/prototype";
import { getPluginInfo, getPrettyUrls, getRedirects } from "ls/api/cloud/sites";
import { STALE } from "ls/queries/staleTime";

export const queries = {
  file: (shortcut: string) => ["file", shortcut.toLowerCase()] as const,
  location: (shortcut: string) =>
    queryOptions({
      queryKey: [...queries.file(shortcut), "location"] as const,
      queryFn: ({ signal }) => getFolderNavByShortcutId(shortcut.toLowerCase(), { signal }),
      staleTime: STALE.SECONDS.FIFTEEN,
    }),
  info: (shortcut: string) =>
    queryOptions({
      queryKey: [...queries.file(shortcut), "info"] as const,
      queryFn: ({ signal }) => getPrototypeInfo(shortcut.toLowerCase(), { signal }),
      staleTime: STALE.SECONDS.FIFTEEN,
    }),
  password: (shortcut: string) =>
    queryOptions({
      queryKey: [...queries.file(shortcut), "password"] as const,
      queryFn: ({ signal }) => getPassword(shortcut.toLowerCase(), { signal }),
    }),
  pages: (shortcut: string) =>
    queryOptions({
      queryKey: [...queries.file(shortcut), "pages"] as const,
      queryFn: ({ signal }) => getPrototypePages(shortcut.toLowerCase(), { signal }),
    }),
  plugins: (shortcut: string) =>
    queryOptions({
      queryKey: [...queries.file(shortcut), "plugins"] as const,
      queryFn: ({ signal }) => getPluginInfo(shortcut.toLowerCase(), { signal }),
    }),
  redirects: (shortcut: string) =>
    queryOptions({
      queryKey: [...queries.file(shortcut), "redirects"] as const,
      queryFn: ({ signal }) => getRedirects(shortcut.toLowerCase(), { signal }),
    }),
  prettyUrls: (shortcut: string) =>
    queryOptions({
      queryKey: [...queries.file(shortcut), "pretty-urls"] as const,
      queryFn: ({ signal }) => getPrettyUrls(shortcut.toLowerCase(), { signal }),
    }),
};

import { VueQueryPlugin } from "@tanstack/vue-query";
import { createHead } from "@unhead/vue";
import { setAutoFreeze } from "immer";
import { DataLoaderPlugin } from "unplugin-vue-router/data-loaders";
import { configure as configureValidation } from "vee-validate";
import { createApp } from "vue";
import { VueRecaptchaPlugin } from "vue-recaptcha";
import App from "./App.vue";
import { router } from "./router";

// disable immer Auto freeze to avoid conflicts with proxies created by Vue itself
setAutoFreeze(false);

configureValidation({
  validateOnBlur: false,
  validateOnChange: false,
  validateOnInput: false,
  validateOnModelUpdate: false,
});

const app = createApp(App);

app.use(DataLoaderPlugin, { router });
app.use(router);
app.use(VueQueryPlugin, { enableDevtoolsV6Plugin: true });
const head = createHead();
app.use(head);
app.use(VueRecaptchaPlugin, {
  v2SiteKey: "will-be-replaced",
});

app.mount("#app");

<script setup lang="ts">
import { TooltipProvider, type TooltipProviderProps, useForwardProps } from "radix-vue";

const props = defineProps<TooltipProviderProps>();
const forwarded = useForwardProps(props);
</script>

<template>
  <TooltipProvider
    v-bind="forwarded"
    :delay-duration="500"
    :skip-delay-duration="300"
  >
    <slot />
  </TooltipProvider>
</template>

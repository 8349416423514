import type { RefOrGetter } from "ls/common/types";
import Axios, { type AxiosInstance } from "axios";
import { setupServer } from "ls/api/common/server";
import { onUnmounted, shallowRef, watch } from "vue";
import { authToken } from "./authTokenStorage";

export function useAxiosInstance(baseUrl: RefOrGetter<string | undefined>) {
  const axiosInstance = shallowRef<AxiosInstance>();

  function create(baseUrl: string) {
    const instance = Axios.create({
      baseURL: baseUrl,
      withCredentials: true,
    });

    setupServer(instance);

    instance.interceptors.request.use(request => {
      const token = authToken.get();
      if (token) {
        request.headers.set("Authorization", token, true);
      }
      return request;
    });

    return instance;
  }

  function dispose() {
    if (!axiosInstance.value) return;
    axiosInstance.value.interceptors.request.clear();
    axiosInstance.value.interceptors.response.clear();
    axiosInstance.value = undefined;
  }

  watch(baseUrl, url => {
    if (!url) {
      dispose();
      return;
    }

    // server already created, reconfigure with new service url
    if (axiosInstance.value) {
      axiosInstance.value.defaults.baseURL = url;
      return;
    }

    axiosInstance.value = create(url);
  }, { immediate: true });

  onUnmounted(() => {
    dispose();
  });

  return axiosInstance;
}

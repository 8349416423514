<script setup lang="ts">
import { ProgressIndicator, ProgressRoot } from "radix-vue";
import { computed } from "vue";

const props = defineProps<{
  total?: number;
  current?: number;
}>();

const percentage = computed(() => {
  if (props.current == null || props.total == null) return 0;
  return props.current / props.total * 100;
});
</script>

<template>
  <ProgressRoot
    :model-value="current ?? null"
    :max="total"
    class="relative h-1.5 overflow-hidden rounded-full bg-slate-200"
    style="transform: translateZ(0)"
  >
    <ProgressIndicator
      :style="`transform: translateX(-${100 - percentage}%)`"
      class="size-full rounded-full bg-blue-500 transition-transform duration-1000 ease-in-out"
    />
  </ProgressRoot>
</template>

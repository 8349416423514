import { queryOptions } from "@tanstack/vue-query";
import { getConfig } from "ls/api/cloud/user";
import { STALE } from "ls/queries/staleTime";

export const queries = {
  config: () =>
    queryOptions({
      queryKey: ["config"] as const,
      queryFn: getConfig,
      staleTime: STALE.INFINITY,
    }),
};

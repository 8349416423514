<script setup lang="ts">
import ClickthroughUploadProgressBatch from "./ClickthroughUploadProgressBatch.vue";
import { useClickthroughUploader } from "./useClickthroughUploader";

const { batches } = useClickthroughUploader();
</script>

<template>
  <div
    class="fixed bottom-10 right-10 w-80 space-y-6"
  >
    <ClickthroughUploadProgressBatch
      v-for="[id, batch] in batches"
      :key="id"
      :batch="batch"
    />
  </div>
</template>

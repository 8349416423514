import { queryOptions } from "@tanstack/vue-query";
import { getRecents } from "ls/api/cloud/fs";

export const queries = {
  recents: () =>
    queryOptions({
      queryKey: ["recents"] as const,
      queryFn: ({ signal }) => getRecents({}, { signal }),
    }),
};

<script setup lang="ts">
import { pluralize } from "@/common/lib";
import SimpleButton from "ls/components/simple-button/SimpleButton.vue";
import { toOrganizationMembers } from "ls/router/builders";
import { useSubInstanceId } from "ls/state/useSubInstance";
import { computed } from "vue";
import { useOrganizationAccessLevel } from "../organizations/useOrganizationAccessLevel";
import { useSubInstanceLicensingInfo } from "../organizations/useSubInstanceLicensingInfo";
import { useCurrentUserInfo } from "../user/useAuth";
import SimpleBanner from "./SimpleBanner.vue";

const subInstanceId = useSubInstanceId();
const { isAdmin } = useOrganizationAccessLevel(subInstanceId);
const user = useCurrentUserInfo();
const { gracePeriodDaysLeft, isInGracePeriod, isExpired } = useSubInstanceLicensingInfo(subInstanceId);

const showInstanceSubscriptionExpired = computed(() => user.value && !user.value.isRpEnterprise && isExpired.value && isInGracePeriod.value);

const unitOfTrial = computed(() => pluralize(gracePeriodDaysLeft.value, "day", "days"));
const daysLeftMessage = computed(() => gracePeriodDaysLeft.value <= 0 ? "" : `in ${gracePeriodDaysLeft.value} ${unitOfTrial.value}`);
</script>

<template>
  <SimpleBanner v-if="showInstanceSubscriptionExpired">
    <template v-if="isAdmin">
      This Axure Cloud for Business plan has expired. Publishers with seats will be limited {{ daysLeftMessage }} unless it is renewed.
      <SimpleButton
        class="text-blue-300"
        variant="link-light"
        :href="toOrganizationMembers(subInstanceId)"
      >
        Manage accounts
      </SimpleButton>
    </template>
    <template v-else>
      This Axure Cloud for Business plan has expired. All publishers with seats will be limited {{ daysLeftMessage }} unless it is renewed. To continue publishing, please ask your admin.
    </template>
  </SimpleBanner>
</template>

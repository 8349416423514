import { queryOptions } from "@tanstack/vue-query";
import { getSamlSettings, getSubInstanceInfo } from "ls/api/cloud/admin";
import { enabledForOrganization } from "ls/api/cloud/disko";
import { getAccounts, getFilesystemMappings, getOrganization, getOrganizations } from "ls/api/cloud/organization";
import { STALE } from "ls/queries/staleTime";

export const queries = {
  organizations: () => ["organizations"] as const,
  organization: (id: string) => [...queries.organizations(), id] as const,

  list: () => queryOptions({
    queryKey: [...queries.organizations(), "list"] as const,
    queryFn: ({ signal }) => getOrganizations({ signal }),
  }),

  info: (id: string) =>
    queryOptions({
      queryKey: [...queries.organization(id), "info"] as const,
      queryFn: ({ signal }) => getOrganization({ organizationId: id }, { signal }),
    }),

  accounts: (id: string) =>
    queryOptions({
      queryKey: [...queries.organization(id), "accounts"] as const,
      queryFn: async ({ signal }) => {
        const response = await getAccounts({ organizationId: id }, { signal });
        return response.Items;
      },
    }),

  filesystemMappings: (id: string) =>
    queryOptions({
      queryKey: [...queries.organization(id), "fs-mappings"] as const,
      queryFn: ({ signal }) => getFilesystemMappings({ organizationId: id }, { signal }),
    }),

  adminInfo: (id: string) =>
    queryOptions({
      queryKey: [...queries.organization(id), "admin-info"] as const,
      queryFn: ({ signal }) => getSubInstanceInfo({ organizationId: id }, { signal }),
    }),

  samlSettings: (id: string) =>
    queryOptions({
      queryKey: [...queries.organization(id), "saml-settings"] as const,
      queryFn: ({ signal }) => getSamlSettings({ signal }),
    }),

  diskoEnabled: (id: string) =>
    queryOptions({
      queryKey: [...queries.organization(id), "disko-enabled"] as const,
      queryFn: ({ signal }) => enabledForOrganization({ organizationId: id }, { signal }),
      staleTime: STALE.INFINITY,
    }),
};

import type { UserMentionedArgs, WorkspaceInvitationDeletedArgs, WorkspaceInvitedArgs } from "@/generated/models";
import type { ListenersMap } from "./types";
import { createVoidEventHook } from "./createVoidEventHook";
import { useAxureCloudHub } from "./useAxureCloudHub";

interface Payload {
  workspaceInvited: WorkspaceInvitedArgs;
  workspaceInvitationDeleted: WorkspaceInvitationDeletedArgs;
  userMentioned: UserMentionedArgs;
}

export function useAxureCloudNotificationsHub() {
  const workspaceInvited = createVoidEventHook<WorkspaceInvitedArgs>();
  const workspaceInvitationDeleted = createVoidEventHook<WorkspaceInvitationDeletedArgs>();
  const userMentioned = createVoidEventHook<UserMentionedArgs>();

  const listeners: ListenersMap<Payload> = {
    workspaceInvited: workspaceInvited.trigger,
    workspaceInvitationDeleted: workspaceInvitationDeleted.trigger,
    userMentioned: userMentioned.trigger,
  };

  useAxureCloudHub("notifications", {
    listeners,
  });

  return {
    workspaceInvited,
    workspaceInvitationDeleted,
    userMentioned,
  };
}

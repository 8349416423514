import type { FilesystemJs, FolderNotificationStateJs, FolderRowJs, OrganizationJs, ShortcutJs } from "@/generated/models";
import type { AllProjectsType } from "../settings/types";
import { jsDateFromAspDateString as toJsDate } from "@/common/lib";
import { isObject } from "@/common/lib/guards";
import { naturalSort, naturalSortCompare } from "@/common/lib/natural-sort";
import { sort } from "fast-sort";
import { custom, type InferOutput, is, literal, object, safeParse } from "valibot";
import { organizationJsSchema } from "../organizations/types";

export const isShortcutJs = (node: unknown): node is ShortcutJs => isObject(node) && "Shortcut" in node && !!node.Shortcut;
export const shortcutJsSchema = custom<ShortcutJs>(isShortcutJs);

export const isFolderRowJs = (node: unknown): node is FolderRowJs => isObject(node) && "FolderId" in node && !!node.FolderId;
export const folderRowJsSchema = custom<FolderRowJs>(isFolderRowJs);

export const isFilesystemJs = (node: unknown): node is FilesystemJs => isObject(node) && "FilesystemId" in node && !!node.FilesystemId;
export const filesystemJsSchema = custom<FilesystemJs>(isFilesystemJs);

export const isAllProjectsType = (node: unknown): node is AllProjectsType => isObject(node) && "type" in node && node.type === "All";
export const isAllProjectsTypeSchema = custom<AllProjectsType>(isAllProjectsType);

const noItemsSchema = object({
  id: literal("no-items"),
});
export type NoItems = InferOutput<typeof noItemsSchema>;
export const isNoItems = (row: unknown): row is NoItems => is(noItemsSchema, row);

export function isFolderNotificationState(node: unknown): node is FolderNotificationStateJs {
  return isObject(node) && "NewCommentShortcut" in node
    && node.NewCommentShortcut !== undefined;
}

export function isOrganizationJs(node: unknown): node is OrganizationJs {
  return safeParse(organizationJsSchema, node).success;
}

const noSharedWithMeWorkspacesSchema = object({
  id: literal("no-shared-with-me-workspaces"),
});
export type NoSharedWithMeWorkspaces = InferOutput<typeof noSharedWithMeWorkspacesSchema>;
export const isNoSharedWithMeWorkspaces = (row: unknown): row is NoSharedWithMeWorkspaces => is(noSharedWithMeWorkspacesSchema, row);

const noOrganizationWorkspacesSchema = object({
  id: literal("no-organization-workspaces"),
  organization: organizationJsSchema,
});
export type NoOrganizationWorkspaces = InferOutput<typeof noOrganizationWorkspacesSchema>;
export const isNoOrganizationWorkspaces = (row: unknown): row is NoOrganizationWorkspaces => is(noOrganizationWorkspacesSchema, row);

export function getName(node: OrganizationJs | FilesystemJs | FolderRowJs | ShortcutJs) {
  return isShortcutJs(node)
    ? node.Name
    : isFolderRowJs(node)
      ? node.FolderName
      : isFilesystemJs(node) ? node.DisplayName : node.Name;
}

const nextNoItemsId = (() => {
  let start = 0;

  return () => {
    return (start++).toString();
  };
})();

export function getId(node: OrganizationJs | FilesystemJs | FolderRowJs | ShortcutJs | NoItems | NoSharedWithMeWorkspaces | NoOrganizationWorkspaces) {
  if (isNoItems(node) || isNoSharedWithMeWorkspaces(node) || isNoOrganizationWorkspaces(node)) {
    return nextNoItemsId();
  }
  if (isNoItems(node)) return nextNoItemsId();
  return isShortcutJs(node)
    ? node.Shortcut
    : isFolderRowJs(node)
      ? node.FolderId
      : isFilesystemJs(node) ? node.FilesystemId : node.Id;
}

export function getLastModified(node: ShortcutJs | FolderRowJs) {
  return isShortcutJs(node) ? getShortcutJsLastModified(node) : getFolderRowJsLastModified(node);
}

export function getShortcutJsLastModified(shortcut: ShortcutJs) {
  return {
    updatedAt: shortcut.ModifiedOn ? toJsDate(shortcut.ModifiedOn) : toJsDate(shortcut.CreatedOn),
    updatedBy: shortcut.ModifiedBy,
  };
}

export function getFolderRowJsLastModified(folder: FolderRowJs) {
  return {
    updatedAt: folder.ModifiedOn && folder.ModifiedOn.Value ? toJsDate(folder.ModifiedOn.Value) : toJsDate(folder.CreatedOn),
    updatedBy: folder.ModifiedOn.Username,
  };
}

export function workspacesSort(rows: FilesystemJs[]) {
  return naturalSort(rows).asc(d => d.DisplayName);
}

export function filesystemSort<T extends OrganizationJs | FilesystemJs | FolderRowJs | ShortcutJs>(rows: T[]): T[] {
  return sort(rows).by([
    { desc: r => isOrganizationJs(r) },
    { desc: r => isFilesystemJs(r) },
    { desc: r => isFolderRowJs(r) },
    { desc: r => isShortcutJs(r) },
    { asc: r => getName(r), comparer: naturalSortCompare },
  ]);
}

import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "16",
  height: "16",
  fill: "none",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("rect", {
      width: "16.003",
      height: "16.003",
      y: "-.002",
      fill: "#FACC15",
      rx: "4"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#FACC15",
      d: "M12.714 4.683a.987.987 0 0 0-1.395-1.396L6.648 7.96a.7.7 0 0 0-.175.29l-.462 1.524a.175.175 0 0 0 .218.218l1.523-.462a.7.7 0 0 0 .29-.174zm-2.161-.635 1.4 1.4Z"
    }, null, -1),
    _createElementVNode("path", {
      stroke: "#000",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "M11.685 7.733a1.086 1.086 0 0 1 0 1.534L8.27 12.684a1.086 1.086 0 0 1-1.535 0L3.318 9.267a1.085 1.085 0 0 1 0-1.534l3.416-3.417a1.085 1.085 0 0 1 1.535 0l.356.356m1.928-.624 1.4 1.4m.761-.765a.987.987 0 0 0-1.395-1.396L6.648 7.96a.7.7 0 0 0-.175.29l-.462 1.524a.175.175 0 0 0 .218.218l1.523-.462a.7.7 0 0 0 .29-.174z"
    }, null, -1)
  ])))
}
export default { render: render }
import type { AxiosProgressEvent } from "axios";
import type { RefOrGetter } from "ls/common/types";
import { computed, readonly, ref, toValue } from "vue";

export function useAxiosProgressStats(event: RefOrGetter<AxiosProgressEvent | undefined>) {
  const current = computed(() => toValue(event)?.lengthComputable ? toValue(event)?.loaded : undefined);
  const total = computed(() => toValue(event)?.total ?? undefined);

  const percentage = computed(() => {
    if (current.value == null || total.value == null) return 0;
    return current.value / total.value * 100;
  });

  return {
    current: readonly(current),
    total: readonly(total),
    percentage,
  };
}

export function useAxiosUploadProgress() {
  const eventRef = ref<AxiosProgressEvent>();
  function onUploadProgress(event: AxiosProgressEvent) {
    eventRef.value = event;
  }

  function reset() {
    eventRef.value = undefined;
  }

  const { current, total, percentage } = useAxiosProgressStats(eventRef);

  return {
    onUploadProgress,
    current,
    total,
    percentage,
    reset,
  };
}

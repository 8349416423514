import type { AxShareHostsConfig } from "@/services/models/config";
import { isElectronRenderer } from "@/common/environment";
import { delay, isIframe } from "@/common/lib";
import { Main, Renderer } from "@/desktop/events";
import { useAxureCloudConfig } from "ls/state/useAxureCloudConfig";

export function useDesktopAppAuthentication() {
  const config = useAxureCloudConfig();

  function mainProcessLogin(config: AxShareHostsConfig) {
    return new Promise<Electron.IpcRendererEvent | void>(resolve => {
      if (!isElectronRenderer) resolve();

      window.AxureCloudNative.ipc.once(Renderer.LoginHandled, resolve);
      window.AxureCloudNative.ipc.send(Main.Login, config);

      // for backwards compatibility when main process won't send Renderer.LoginHandled
      // give a moment to login to complete, and then resolve promise
      delay(2000).then(() => resolve());
    });
  }

  async function setAuth(token: string) {
    if (!isElectronRenderer) {
      return;
    }
    if (!config.value) {
      return;
    }
    try {
      const desktopConfig: AxShareHostsConfig = {
        AccountServiceSecureUrl: config.value.AccountServiceSecureUrl,
        AxShareHostSecureUrl: config.value.AxShareHostSecureUrl,
        authToken: token,
      };

      await mainProcessLogin(desktopConfig);
    } catch (e) {
      // passing auth into to main process may fail if inside iframe, like in-app prototype
      // in this case swallow error if inside iframe,
      // otherwise rethrow
      if (!isIframe()) {
        throw e;
      }
    }
  }

  function logout() {
    return new Promise<Electron.IpcRendererEvent | void>(resolve => {
      if (!isElectronRenderer) resolve();

      window.AxureCloudNative.ipc.once(Renderer.LogoutHandled, resolve);
      window.AxureCloudNative.ipc.send(Main.Logout);

      // for backwards compatibility when main process won't send Renderer.LogoutHandled
      // give a moment to logout to complete, and then resolve promise
      delay(2000).then(() => resolve());
    });
  }

  return {
    setAuth,
    logout,
  };
}

export const isArray = Array.isArray;
export const objectToString = Object.prototype.toString;
export const toTypeString = (value: unknown): string => objectToString.call(value);
export const isMap = (val: unknown): val is Map<unknown, unknown> => toTypeString(val) === "[object Map]";
export const isSet = (val: unknown): val is Set<unknown> => toTypeString(val) === "[object Set]";

export const isDate = (val: unknown): val is Date => toTypeString(val) === "[object Date]";
export const isRegExp = (val: unknown): val is RegExp => toTypeString(val) === "[object RegExp]";
export const isFunction = (val: unknown): val is (...args: unknown[]) => unknown => typeof val === "function";
export const isBoolean = (val: unknown): val is boolean => typeof val === "boolean";
export const isNumber = (val: unknown): val is number => typeof val === "number";
export const isString = (val: unknown): val is string => typeof val === "string";
export const isSymbol = (val: unknown): val is symbol => typeof val === "symbol";
export const isObject = (val: unknown): val is Record<string, unknown> => val !== null && typeof val === "object";

import type { Action, AddHotspotRequest, CreateMasterJs, CreateMasterRequest, DeleteArtboardRequest, DeleteArtboardsRequest, DeleteHotspotRequest, DeviceDropDownOption, DeviceType, EditHotspotRequest, ExpoStaticContent, HotspotAddResponse, HotspotDeleteResponse, HotspotEditResponse, ModifyMasterRequest, Platform, Project, ReorderArtboardRequest, ResponseObject, SetHeaderFooterHeightRequest, ShortcutCreateJs, UpdatePlatformRequest } from "@/generated/models";
import type { RequestOptions } from "../common/server";
import { objectToFormData } from "@/services/utils/formData";
import { server } from "./server";

export interface CreateModel {
  FolderId?: string;
  FilesystemId?: string;
  Name?: string;
  Platform?: DeviceType;
}
export async function create({ folderId, filesystemId, name, deviceType }: { folderId?: string; filesystemId?: string; deviceType?: DeviceType; name?: string }) {
  const model: CreateModel = {
    FolderId: folderId,
    FilesystemId: filesystemId,
    Name: name,
    Platform: deviceType,
  };
  const formData = objectToFormData(model);
  const response = await server.post(`expo/create`, formData);
  return response.data as ShortcutCreateJs;
}

export async function addArtboard(
  { shortcut, files, skipPublishNotes }: { shortcut: string; files: File[] | File; skipPublishNotes?: boolean },
  { signal, onUploadProgress }: RequestOptions = {},
) {
  const model = {
    fileData: files,
    skipPublishNote: skipPublishNotes,
  };
  const formData = objectToFormData(model);
  const response = await server.post(`expo/addArtboard/${shortcut}`, formData, { signal, onUploadProgress });
  return response.data as ResponseObject;
}

export async function setZoom(
  { shortcut, zoom }: { shortcut: string; zoom: number },
) {
  const model = {
    zoom,
  };
  const response = await server.post(`expo/setZoom/${shortcut}`, model);
  return response.data as ResponseObject;
}

export async function getProject(
  shortcut: string,
  { signal }: RequestOptions = {},
) {
  const response = await server.get(`expo/getProject/${shortcut}`, { signal });
  return response.data as Project;
}

export async function getStaticContent(
  shortcut: string,
  { signal }: RequestOptions = {},
) {
  const response = await server.get(`expo/getStaticContent/${shortcut}`, { signal });
  return response.data as ExpoStaticContent;
}

export async function getDeviceList(
  { signal }: RequestOptions = {},
) {
  const response = await server.get(`expo/getDeviceList`, { signal });
  return response.data as DeviceDropDownOption[];
}

export async function updatePlatform(shortcut: string, platform: Platform) {
  const data: UpdatePlatformRequest = { platform };
  return server.post(`expo/updatePlatform/${shortcut}`, data);
}

export async function addHotspot(shortcut: string, artboardId: string, action: Action) {
  const data: AddHotspotRequest = {
    tag: "",
    hotspot: action.Hotspot,
    setting: action.Setting,
    type: action.Type,
    artboardId: action.IsMaster ? undefined : artboardId,
    masterId: action.IsMaster ? action.ParentId : undefined,
    relativeToBottom: action.RelativeToBottom,
  };

  const response = await server.post(`expo/addHotspot/${shortcut}`, data);
  return response.data as HotspotAddResponse;
}

export async function editHotspot(shortcut: string, originalAction: Action, action: Action) {
  const data: EditHotspotRequest = {
    sourceId: originalAction.ParentId,
    targetId: action.ParentId,
    actionId: action.Id,
    hotspot: action.Hotspot,
    setting: action.Setting,
    type: action.Type,
    relativeToBottom: action.RelativeToBottom,
    isSourceMaster: originalAction.IsMaster,
    isTargetMaster: action.IsMaster,
  };

  const response = await server.post(`expo/editHotspot/${shortcut}`, data);
  return response.data as HotspotEditResponse;
}

export async function deleteHotspot(shortcut: string, isMaster: boolean, sourceId: string, actionId: string) {
  const data: DeleteHotspotRequest = {
    isMaster,
    sourceId,
    actionId,
  };

  const response = await server.post(`/expo/deleteHotspot/${shortcut}`, data);
  return response.data as HotspotDeleteResponse;
}

export async function createMaster({ shortcut, name, artboardId }: { shortcut: string; name: string; artboardId: string }) {
  const data: CreateMasterRequest = {
    artboardId,
    name,
  };

  const response = await server.post(`expo/createMaster/${shortcut}`, data);
  return response.data as CreateMasterJs;
}

export async function renameMaster({ shortcut, masterId, name }: { shortcut: string; masterId: string; name: string }) {
  const data = {
    masterId,
    name,
  };
  const response = await server.post(`expo/renameMaster/${shortcut}`, data);
  return response.data as CreateMasterJs;
}

export async function copyMaster({ shortcut, masterId }: { shortcut: string; masterId: string }) {
  const data = {
    masterId,
  };
  const response = await server.post(`expo/copyMaster/${shortcut}`, data);
  return response.data as CreateMasterJs;
}

export async function deleteMaster({ shortcut, masterId }: { shortcut: string; masterId: string }) {
  const data = {
    masterId,
  };
  const response = await server.post(`expo/deleteMaster/${shortcut}`, data);
  return response.data as ResponseObject;
}

export async function addArtboardMaster(
  shortcut: string,
  artboardId: string,
  masterId: string,
) {
  const data: ModifyMasterRequest = {
    artboardId,
    masterId,
    name: "",
  };

  const response = await server.post(`expo/addMaster/${shortcut}`, data);
  return response.data as ResponseObject;
}

export async function removeArtboardMaster(
  shortcut: string,
  artboardId: string,
  masterId: string,
) {
  const data: ModifyMasterRequest = {
    artboardId,
    masterId,
    name: "",
  };

  const response = await server.post(`expo/removeMaster/${shortcut}`, data);
  return response.data as ResponseObject;
}

export async function deleteArtboard(shortcut: string, artboardId: string) {
  const data: DeleteArtboardRequest = { artboardId };

  const response = await server.post(`expo/deleteArtboard/${shortcut}`, data);
  return response.data as ResponseObject;
}

export async function deleteArtboards(shortcut: string, artboardIds: string[]) {
  const data: DeleteArtboardsRequest = { artboardIds };

  const response = await server.post(`expo/deleteArtboards/${shortcut}`, data);
  return response.data as ResponseObject;
}

export async function reorderArtboard(
  { shortcut, artboardId, tailArtboardId }: { shortcut: string; artboardId: string; tailArtboardId: string },
) {
  const data: ReorderArtboardRequest = {
    targetAbId: artboardId,
    tailAbId: tailArtboardId,
    fullResequence: false,
  };

  const response = await server.post(`expo/reorderArtboard/${shortcut}`, data);
  return response.data as ResponseObject;
}

export async function setHeaderFooterHeight({
  shortcut,
  artboardId,
  height,
  isHeader,
}: { shortcut: string; artboardId: string; height: number; isHeader: boolean }) {
  const data: SetHeaderFooterHeightRequest = {
    artboardId,
    height,
    isHeader,
  };

  const response = await server.post(`expo/setHeaderFooterHeight/${shortcut}`, data);
  return response.data as ResponseObject;
}

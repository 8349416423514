/* eslint-disable no-restricted-syntax */
export const enum Main {
  Login = "login",
  ConfigGet = "config-get",
  ConfigSet = "config-set",
  Logout = "logout",
  VuexCommit = "vuex-commit",
  VuexDispatch = "vuex-dispatch",
  DesktopMenu = "desktop-menu",
  DesktopMinimize = "desktop-minimize",
  DesktopMaximize = "desktop-maximize",
  DesktopClose = "desktop-close",
  OpenExternal = "open-external",
  PerformProxyAuth = "perform-proxy-authentication",
  CancelProxyAuth = "cancel-proxy-athenticatoin",
  DownloadingUpdatesProgress = "downloading-updates-progress",
  DownloadingUpdatesCancel = "downloading-updates-cancel",
  LogoutPerformed = "logout-performed",
  GetSystemColor = "get-system-color",
  GetWindowState = "get-window-state",
  GetLastLoginState = "get-last-login-state",
  LastLoginStateChanged = "last-login-state-changed",
  // this event is triggered from axshare_mvc project
  BackToLoginScreenFromView = "back-to-login-screen-from-view",

  // artboards export
  ExportArtboardCancelRequested = "export-artboard-cancel-requested",
  ShowBadge = "show-badge",
  BadgeDrawn = "badge-drawn",
  ResizeBrowserWindow = "resize-browser-window",
}

export const enum Renderer {
  VuexDispatchAck = "vuex-dispatch-ack-",
  VuexDispatchComplete = "vuex-dispatch-complete-",
  ConfigProvide = "config-provide",
  LoginHandled = "login-handled",
  LogoutHandled = "logout-handled",
  ProvideSystemColor = "provide-system-color",
  Logout = "logout",

  DrawBadge = "draw-badge",

  // artboards export
  ExportStarted = "artboards-export-started",
  ExportFinished = "artboards-export-finished",
  ExportErrored = "artboards-export-errored",
  ExportArtboardStarted = "artboards-export-artboard-started",
  ExportArtboardUploadStarted = "artboards-export-artboard-upload-started",
  ExportArtboardProgress = "artboards-export-artboard-progress",
  ExportArtboardSuccess = "artboards-export-artboard-success",
  ExportArtboardCanceled = "artboards-export-artboard-canceled",
  ExportArtboardError = "artboards-export-artboard-error",
}

export const IPC_EVENT_PREFIX = "AXURE_CLOUD_DESKTOP_";

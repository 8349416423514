export default {
  sitemap: {
    id: "sitemapHost",
    gid: 1,
  },
  notes: {
    id: "pageNotesHost",
    gid: 2,
  },
  discussions: {
    id: "feedbackHost",
    gid: 4,
  },
  handoff: {
    id: "handoffHost",
    gid: 5,
  },
  hotSpots: {
    id: "hi",
    show: "1",
  },
  commentMarkers: {
    id: "dp",
    hide: "0",
  },
  noteMarkers: {
    id: "fn",
    hide: "0",
  },
  scale: {
    id: "sc",
  },
  adaptiveView: {
    id: "view",
    default: "auto",
  },
};

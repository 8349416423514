export const isDevelopment = import.meta.env.DEV;
export const isProduction = import.meta.env.PROD;
export const isOnPrem = import.meta.env.VITE_APP_ON_PREM === "true";
export const isLatest = import.meta.env.VITE_APP_AXSHARE_LATEST === "true";
export const isExpoPreviewPinsDisabled = import.meta.env.VITE_APP_EXPO_PREVIEW_PINS_DISABLED === "true";
export const commitHash = isDevelopment ? import.meta.env.VITE_APP_COMMIT_HASH : "";

function isElectronRendererFn() {
  if (typeof window === "undefined") return false;
  if (window.AxureCloudNative !== undefined) return true;
  const { process }: { process: NodeJS.Process } = window as any;
  if (typeof process === "object" && process.type === "renderer") return true;
  if (
    typeof navigator === "object"
    && typeof navigator.userAgent === "string"
    && (
      navigator.userAgent.includes("Electron")
      || navigator.userAgent.includes("Axure Cloud")
      || navigator.userAgent.includes("axure-cloud")
    )
  ) {
    return true;
  }
  return false;
}

function isElectronMainFn() {
  // eslint-disable-next-line node/prefer-global/process
  return typeof process !== "undefined" && typeof process.versions === "object" && !!process.versions.electron;
}

export const isElectronBuild = import.meta.env.VITE_APP_IS_ELECTRON === "true";
export const isElectronRenderer = isElectronRendererFn();
export const isElectronMain = isElectronMainFn();
export const isElectron = isElectronMain || isElectronRenderer;

function isDesktopLocalFn() {
  return (isElectronBuild && (import.meta.env.PROD && import.meta.env.VITE_APP_IS_ELECTRON_LOCAL === "true"))
    || import.meta.env.VITE_APP_DESKTOP_LOGIN === "true";
}
export const isDesktopLocal = isDesktopLocalFn();

<script setup lang="ts">
import { Main } from "@/desktop/events";
import { MenuIcon } from "lucide-vue-next";

const { process, ipc } = window.AxureCloudNative;
const isWindows = process.platform === "win32";

function handleMenuClick() {
  ipc.send(Main.DesktopMenu);
}
</script>

<template>
  <div
    class="h-10 shrink-0 bg-zinc-800 desktop-draggable"
  >
    <template v-if="isWindows">
      <div class="flex h-full items-center justify-between">
        <div class="flex h-full items-center gap-2">
          <button
            class="inline-flex size-10 items-center justify-center px-2 text-white desktop-non-draggable hover:bg-zinc-700"
            aria-label="Axure Cloud menu"
            variant="secondary"
            @click="handleMenuClick"
          >
            <MenuIcon class="size-4" aria-hidden="true" />
          </button>
          <div class="text-white">
            Axure Cloud
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

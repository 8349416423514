<script setup lang="ts">
import SimpleButton from "ls/components/simple-button/SimpleButton.vue";
import { toOrganizationMembers } from "ls/router/builders";
import { useSubInstanceId } from "ls/state/useSubInstance";
import { computed } from "vue";
import { useOrganizationAccessLevel } from "../organizations/useOrganizationAccessLevel";
import { useCurrentUserInfo } from "../user/useAuth";
import SimpleBanner from "./SimpleBanner.vue";

const subInstanceId = useSubInstanceId();
const { isAdmin } = useOrganizationAccessLevel(subInstanceId);
const user = useCurrentUserInfo();
const isUserUnderTrialLicense = computed(() => !!user.value && user.value.isRpEnterprise && !user.value.isWhitelisted);
</script>

<template>
  <template v-if="isUserUnderTrialLicense">
    <SimpleBanner v-if="user?.isTrialActive">
      Your trial has {{ user.trialDaysLeft }} days left.
      <SimpleButton
        v-if="isAdmin"
        class="text-blue-300"
        variant="link-light"
        :href="toOrganizationMembers(subInstanceId)"
      >
        Manage accounts
      </SimpleButton>
      <span v-else>
        To continue publishing after the trial, please ask your admin.
      </span>
    </SimpleBanner>
    <SimpleBanner v-else>
      Your trial has expired.
      <SimpleButton
        v-if="isAdmin"
        class="text-blue-300"
        variant="link-light"
        :href="toOrganizationMembers(subInstanceId)"
      >
        Manage accounts
      </SimpleButton>
      <span v-else>
        To continue publishing, please ask your admin.
      </span>
    </SimpleBanner>
  </template>
</template>

<script setup lang="ts">
import { ComboboxGroup, type ComboboxGroupProps, useForwardProps } from "radix-vue";

const props = defineProps<ComboboxGroupProps>();
const forwardedProps = useForwardProps(props);
</script>

<template>
  <ComboboxGroup
    v-bind="forwardedProps"
    class="overflow-hidden p-1"
  >
    <slot />
  </ComboboxGroup>
</template>

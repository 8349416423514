<template>
  <div class="grid min-h-full grid-rows-[minmax(0,15%)_auto_auto] justify-items-center gap-y-4 p-4">
    <div class="row-start-1 w-full mb-auto sm:row-start-2 flex min-w-0 justify-center">
      <slot />
    </div>
    <div class="row-start-3 w-full self-end text-center text-slate-500">
      © 2002-{{ new Date().getFullYear() }} Axure Software Solutions, Inc. |
      <a
        target="_blank"
        href="https://www.axure.com/patents"
      >
        www.axure.com/patents
      </a>
    </div>
  </div>
</template>

import { whenever } from "@vueuse/core";

export function wheneverOnceAsync<T>(watcher: () => false | T | null | undefined, signal?: AbortSignal) {
  const value = watcher();
  if (value) return value;

  const promise = new Promise<T>((resolve, reject) => {
    if (signal !== undefined) {
      if (signal.aborted) {
        reject(signal.reason);
      }
      signal.addEventListener("abort", () => reject(signal.reason), { once: true });
    }

    whenever(watcher, resolve, { immediate: true, once: true });
  });

  return promise;
}

<script setup lang="ts">
import { DialogContent, DialogDescription, DialogPortal, DialogTitle } from "radix-vue";
import { ref } from "vue";
import { SimpleDialog, SimpleDialogOverlay } from "../simple-dialog";
import Command from "./Command.vue";
import { useProvideCommandContext } from "./context";

defineProps<{
  title: string;
  description: string;
}>();

const searchTerm = defineModel<string>("searchTerm");
useProvideCommandContext({
  searchTerm,
  isLoading: ref(false),
});
</script>

<template>
  <SimpleDialog>
    <DialogPortal>
      <SimpleDialogOverlay>
        <div class="fixed inset-0 w-screen overflow-y-auto sm:pt-0">
          <div
            class="grid min-h-full grid-rows-1 justify-items-center sm:grid-rows-[minmax(0,15%)_auto] sm:p-4"
          >
            <DialogContent
              class="w-full min-w-0 rounded-sm bg-white shadow-lg sm:row-start-2 sm:mb-auto sm:max-w-2xl"
              @close-auto-focus.prevent
            >
              <DialogTitle v-if="title" class="sr-only">
                {{ title }}
              </DialogTitle>
              <DialogDescription v-if="description" class="sr-only">
                {{ description }}
              </DialogDescription>
              <Command
                v-model:search-term="searchTerm"
              >
                <slot />
              </Command>
            </DialogContent>
          </div>
        </div>
      </SimpleDialogOverlay>
    </DialogPortal>
  </SimpleDialog>
</template>

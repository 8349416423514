import type { RouteLocationNormalizedLoaded, RouteLocationRaw } from "vue-router";
import { isString } from "@/common/general";
import { isRelativeUrl } from "@/common/lib/string";
import { toHome } from "./builders";
import { redirectParameterName } from "./constants";

export function redirectOnSuccessfulSignIn(route: RouteLocationNormalizedLoaded): RouteLocationRaw {
  const redirect = route.query[redirectParameterName];
  if (isString(redirect)) {
    const redirectValue = window.decodeURIComponent(redirect);

    // only allow redirects by param to relative URLs
    if (isRelativeUrl(redirectValue)) {
      return redirectValue;
    }

    return toHome();
  }

  return toHome();
}

<script setup lang="ts">
import { onMounted, onUnmounted } from "vue";
import { useCommandContext } from "./context";

const { isLoading } = useCommandContext();
onMounted(() => {
  isLoading.value = true;
});

onUnmounted(() => {
  isLoading.value = false;
});
</script>

<!-- eslint-disable vue/valid-template-root -->
<template>
</template>

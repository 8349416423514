import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "16",
  height: "16",
  fill: "none",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("rect", {
      width: "16",
      height: "16",
      fill: "#7DD3FC",
      rx: "4"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#000",
      d: "M3.607 11V5.182h1.966q.68 0 1.12.233.436.23.647.633.21.404.21.918 0 .515-.21.912t-.645.625q-.435.224-1.11.224H3.992v-.636H5.56q.466 0 .75-.136.288-.136.415-.387.13-.253.13-.602a1.35 1.35 0 0 0-.13-.61.9.9 0 0 0-.417-.404q-.288-.145-.759-.145H4.311V11zm2.739-2.614L7.777 11H6.96L5.55 8.386zM8.724 11V5.182h1.966q.685 0 1.12.247.437.244.647.662.21.417.21.932 0 .514-.21.934-.207.42-.642.67-.435.248-1.114.248H9.292V8.25h1.387q.468 0 .752-.162a.97.97 0 0 0 .412-.437 1.5 1.5 0 0 0 .131-.628q0-.35-.13-.625a.93.93 0 0 0-.415-.432q-.288-.16-.762-.16H9.43V11z"
    }, null, -1)
  ])))
}
export default { render: render }
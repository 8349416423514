import type { RouteLocationNormalized, RouteMeta, Router } from "vue-router";
import { isFunction } from "@/common/general";
import { wheneverOnceAsync } from "ls/composables/wheneverOnceAsync";
import { useAccountService } from "ls/features/user/useAccountService";
import { toValue } from "vue";

type RouteRequiresAuth = (() => Promise<boolean> | boolean) | boolean | undefined;

function isRouteRequiresAuthMeta(meta: RouteMeta): meta is { requiresAuth: RouteRequiresAuth } {
  return meta.requiresAuth !== undefined;
}

async function routeRequiresAuth(to: RouteLocationNormalized): Promise<boolean> {
  // all routes require auth by default
  if (!to.meta) return true;
  if (!isRouteRequiresAuthMeta(to.meta)) return true;

  const { requiresAuth }: { requiresAuth: RouteRequiresAuth } = to.meta;
  if (requiresAuth === undefined) return true;

  if (isFunction(requiresAuth)) return requiresAuth();
  return requiresAuth;
}

export function useAuthGuard(router: Router) {
  router.beforeEach(async to => {
    const client = useAccountService();

    if (client.isAuthenticated.value) {
      return true;
    }

    if (!client.isLoading.value) {
      await client.checkSession();
    }

    await wheneverOnceAsync(() => !toValue(client.isLoading));

    if (!(await routeRequiresAuth(to))) {
      return true;
    }

    // Recheck auth status since "checkSession" may change it
    if (client.isAuthenticated.value) {
      return true;
    }

    const loginUrl = client.getLoginFullUrl(to);
    if (!loginUrl) {
      throw new Error("Attempted to navigate to account service for login but URL was undefined");
    }
    window.location.href = loginUrl;
    return false;
  });
}

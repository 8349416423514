import Axios from "axios";
import { useAccountService } from "ls/features/user/useAccountService";
import { setupServer } from "../common/server";
import { cloudApiUrl } from "../urls";

export const server = Axios.create({
  baseURL: cloudApiUrl,
  withCredentials: import.meta.env.DEV,
});

setupServer(server);

server.interceptors.request.use(request => {
  const accountService = useAccountService();
  if (accountService.user.value) {
    const token = accountService.user.value.authToken;
    request.headers.set("Authorization", token, true);
  }
  return request;
});

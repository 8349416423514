import { createSharedComposable } from "@vueuse/core";
import { ref } from "vue";

function useCommandKControlsFn() {
  const isOpen = ref(false);

  return {
    isOpen,
  };
}

export const useCommandKControls = createSharedComposable(useCommandKControlsFn);

function getIsIOS() {
  return !!window?.navigator?.userAgent && /iP(?:ad|hone|od)/.test(window.navigator.userAgent);
}

function getIsMacOS() {
  return !!window?.navigator?.userAgent && /Mac/.test(window.navigator.userAgent);
}

function getIsSafari() {
  return !!window?.navigator?.userAgent && /Version\/[0-9._].*Safari/.test(window.navigator.userAgent);
}

export const isIOS = getIsIOS();
export const isMacOS = getIsMacOS();
export const isApple = isIOS || isMacOS;
export const isSafari = getIsSafari();

import { queryOptions } from "@tanstack/vue-query";
import { getUserSearchData } from "ls/api/cloud/fs";

export const queries = {
  search: () =>
    queryOptions({
      queryKey: ["search"] as const,
      queryFn: ({ signal }) => getUserSearchData({ signal }),
    }),
};

import { useQuery } from "@tanstack/vue-query";
import { computed } from "vue";
import { queries } from "./queries";
import { useAccountService } from "./useAccountService";

export function useAuth() {
  const { user, isAuthenticated, error, login, logout, updateUserProfileName, uploadUserProfileImg, deleteUserProfileImg, changeAccountInfo, forgotPassword } = useAccountService();

  return {
    user,
    isAuthenticated,
    error,
    login,
    logout,
    updateUserProfileName,
    uploadUserProfileImg,
    deleteUserProfileImg,
    changeAccountInfo,
    forgotPassword,
  };
}

export function useCurrentUserInfo() {
  const { user } = useAuth();
  const options = computed(() => {
    return {
      ...queries.info(),
      enabled: !!user.value,
    };
  });
  const { data: userInfo } = useQuery(options);
  return userInfo;
}

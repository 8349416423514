import type { Ref } from "vue";
import { createInjectionState } from "@vueuse/core";
import { useContextOrThrow } from "ls/composables/useContextOrThrow";

export interface UseCommandContextOptions {
  searchTerm: Ref<string | undefined>;
  isLoading: Ref<boolean>;
}

const [useProvideCommandContext, useCommandContextFn] = createInjectionState((options: UseCommandContextOptions) => {
  return options;
});

export { useProvideCommandContext };
export const useCommandContext = useContextOrThrow(useCommandContextFn);

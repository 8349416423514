import { hoursToMilliseconds, minutesToMilliseconds, secondsToMilliseconds } from "date-fns";

export const STALE = {
  SECONDS: {
    FIFTEEN: secondsToMilliseconds(15),
  },
  MINUTES: {
    ONE: minutesToMilliseconds(1),
  },
  HOURS: {
    ONE: hoursToMilliseconds(1),
  },
  INFINITY: Number.POSITIVE_INFINITY,
};

<script setup lang="ts">
import { useQueryClient } from "@tanstack/vue-query";
import { ModK } from "ls/common/keyboardShortcuts";
import { useKeyboardShortcut } from "ls/common/useKeyboardShortcut";
import { CommandDialog, CommandInput, CommandList } from "ls/components/command";
import { ArrowUpDownIcon, CornerDownLeftIcon } from "lucide-vue-next";
import { ref, watchEffect } from "vue";
import { queries as recentsQueries } from "../recents/queries";
import CommandPageRecents from "./CommandPageRecents.vue";
import CommandPageSearch from "./CommandPageSearch.vue";
import { queries } from "./queries";
import { useCommandKControls } from "./useCommandKControls";

const { isOpen } = useCommandKControls();

useKeyboardShortcut(ModK, event => {
  event.preventDefault();
  isOpen.value = true;
});

const searchTerm = ref("");
const queryClient = useQueryClient();
void queryClient.prefetchQuery(queries.search());
void queryClient.prefetchQuery(recentsQueries.recents());

watchEffect(() => {
  if (!isOpen.value) {
    searchTerm.value = "";
  }
});
</script>

<template>
  <CommandDialog
    v-if="isOpen"
    v-model:open="isOpen"
    v-model:search-term="searchTerm"
    title="Search"
    description="Search workspaces, folders and files across all your organizations"
  >
    <CommandInput placeholder="Search" @close="isOpen = false" />
    <CommandList>
      <CommandPageSearch
        v-if="searchTerm"
        @select="isOpen = false"
      />
      <CommandPageRecents
        v-if="!searchTerm"
        @select="isOpen = false"
      />
    </CommandList>
    <div class="flex gap-4 border-t border-slate-300 px-3 py-1 text-xs/6 text-slate-500">
      <div class="flex items-center gap-1">
        Select
        <kbd>
          <ArrowUpDownIcon class="size-3" aria-hidden="true" />
          <span class="sr-only">Arrow Up Arrow Down</span>
        </kbd>
      </div>
      <div class="flex items-center gap-1">
        Open
        <kbd>
          <CornerDownLeftIcon class="size-3" aria-hidden="true" />
          <span class="sr-only">Enter</span>
        </kbd>
      </div>
      <div class="ml-auto flex items-center gap-1">
        Search <kbd>{{ ModK.label }}</kbd>
      </div>
    </div>
  </CommandDialog>
</template>

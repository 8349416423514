function authTokenStorage() {
  let token: string | undefined;

  function get(): string | undefined {
    return token;
  }

  function set(authToken: string) {
    token = authToken;
  }

  function clear() {
    token = undefined;
  }

  return { get, set, clear };
}

export const authToken = authTokenStorage();

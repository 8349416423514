import { computed } from "vue";
import { useAxureCloudConfig } from "./useAxureCloudConfig";

export function useIsSubInstance() {
  const config = useAxureCloudConfig();
  return computed(() => config.value?.IsSubInstance ?? false);
}

export function useSubInstanceId() {
  const config = useAxureCloudConfig();
  return computed(() => config.value?.SubInstanceId ?? "");
}

import { createTwoWayMappers } from "@/common/lib/object";
import { FilesystemDefaultMappingType } from "@/generated/models";

export const subInstanceOrganizationPermissions = {
  "view-only": {
    type: "view-only",
    displayText: "View only",
    description: "Anyone in your organization can join this workspace and view, comment, and inspect files",
  },
  "can-edit": {
    type: "can-edit",
    displayText: "Can edit",
    description: "Anyone in your organization can join this workspace, invite members, and edit files",
  },
  "invite-only": {
    type: "invite-only",
    displayText: "Invite-only",
    description: "Only invited members in your organization can join this workspace",
  },
} as const;

export type SubInstanceOrganizationPermissionType = keyof typeof subInstanceOrganizationPermissions;

const filesystemDefaultMappingMap = {
  "can-edit": FilesystemDefaultMappingType.CanEdit,
  "view-only": FilesystemDefaultMappingType.ViewOnly,
  "invite-only": FilesystemDefaultMappingType.InviteOnly,
} as const satisfies Record<SubInstanceOrganizationPermissionType, FilesystemDefaultMappingType>;

export const [toCloudDefaultMappingType, fromCloudDefaultMappingType] = createTwoWayMappers(filesystemDefaultMappingMap);

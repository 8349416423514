import type { HistoryListingJs, ListJs, PluginLocation, RedirectJs, ResponseObject, SeoJs, ShortcutPluginInfo } from "@/generated/models";
import type { AddPluginResponse, EditSeoJs } from "@/services/models/sites";
import type { RequestOptions } from "../common/server";
import { objectToFormData } from "@/services/utils/formData";
import { server } from "./server";

interface AllowOnDemandModel {
  shortcut: string;
  allow: boolean;
}
export function allowOnDemand({ shortcut, allow }: { shortcut: string; allow: boolean }) {
  const model: AllowOnDemandModel = {
    shortcut,
    allow,
  };
  const formData = objectToFormData(model);
  return server.post("sites/allowOnDemand", formData);
}

export interface ProjectHistoryPagination {
  descending?: boolean;
  rowsPerPage: number;
  sortBy?: number;
  totalItems?: number;
  beforeRevision?: number;
  untilTime?: Date;
}

enum SortByColumn {
  RevisionNumber,
  User,
}

export async function getHistory(shortcut: string, pagination: {
  descending?: boolean;
  rowsPerPage?: number;
  sortBy?: number;
  totalItems?: number;
  beforeRevision?: number;
  untilTime?: Date;
} = {}, { signal }: RequestOptions = {}) {
  const {
    rowsPerPage: resultsPerPage = 0,
    sortBy: sortByColumnNum = SortByColumn.RevisionNumber,
    descending: sortDescending = true,
    beforeRevision = 0,
    untilTime,
  } = pagination;

  const response = await server.get("sites/getHistory", {
    params: {
      resultsPerPage,
      sortByColumnNum,
      sortDescending,
      beforeRevision,
      untilTime,
      shortcut,
    },
    signal,
  });

  return response.data as HistoryListingJs;
}

export async function getPluginInfo(shortcut: string, { signal }: RequestOptions = {}) {
  const response = await server.get("sites/getPluginInfo", {
    params: {
      shortcut,
    },
    signal,
  });

  return response.data as ShortcutPluginInfo;
}

export interface PluginModel {
  PluginName: string;
  PluginPriority: number;
  PluginLocation: PluginLocation;
  PluginContent: string;
  PluginMappingId: string;
  ElementLabel?: string | null;
}

export async function addPlugin(shortcut: string, model: Omit<PluginModel, "PluginMappingId">) {
  const formData = objectToFormData({ shortcut, ...model });
  const response = await server.post("sites/addPlugin", formData);

  return response.data as AddPluginResponse;
}

export async function editPlugin(shortcut: string, model: PluginModel) {
  const formData = objectToFormData({ shortcut, ...model });
  const response = await server.post("sites/editPlugin", formData);

  return response.data as ResponseObject;
}

export async function getRedirects(shortcut: string, { signal }: RequestOptions = {}) {
  const response = await server.get("sites/getRedirects", {
    params: {
      shortcut,
    },
    signal,
  });

  return response.data as ListJs<RedirectJs>;
}

export async function getPrettyUrls(shortcut: string, { signal }: RequestOptions = {}) {
  const response = await server.get("sites/getseoinfo", {
    params: {
      shortcut,
    },
    signal,
  });

  return response.data as ListJs<SeoJs>;
}

export async function editPrettyUrl(shortcut: string, model: EditSeoJs) {
  const formData = objectToFormData({ shortcut, ...model });
  const response = await server.post("sites/editseoinfo", formData);

  return response.data as SeoJs;
}

export async function setRootPage(shortcut: string, rootPage: string, clearRoot: boolean) {
  const formData = objectToFormData({ shortcut, rootPage, clearRoot });
  const response = await server.post("sites/setrootpage", formData);

  return response.data as ResponseObject;
}

export async function set404Page(shortcut: string, notFoundPage: string, clear404: boolean) {
  const formData = objectToFormData({ shortcut, notFoundPage, clear404 });
  const response = await server.post("sites/set404page", formData);

  return response.data as ResponseObject;
}

export interface RedirectModel {
  frompath: string;
  topath: string;
  redirectId?: string;
}

export async function addRedirect(shortcut: string, model: RedirectModel) {
  const formData = objectToFormData({ shortcut, ...model });
  const response = await server.post("sites/addRedirect", formData);

  return response.data as RedirectJs;
}

export async function editRedirect(shortcut: string, model: RedirectModel) {
  const formData = objectToFormData({ shortcut, ...model });
  const response = await server.post("sites/editRedirect", formData);

  return response.data as RedirectJs;
}

export async function deleteRedirect(shortcut: string, redirectId: string) {
  const formData = objectToFormData({
    shortcut,
    RedirectId: redirectId,
  });
  const result = await server.post("sites/deleteRedirect", formData);
  return result.data as ResponseObject;
}

export interface PluginPagesModel {
  PagesToInclude?: string[];
  PagesToExclude?: string[];
  PluginMappingId: string;
  InsertAllPages: boolean;
}

export async function editPluginPages(shortcut: string, model: PluginPagesModel) {
  const formData = objectToFormData({
    shortcut,
    ...model,
  });

  const response = await server.post("sites/editPluginPages", formData);
  return response.data as ResponseObject;
}

export async function deletePlugin(shortcut: string, pluginMappingId: string) {
  const formData = objectToFormData({
    shortcut,
    PluginMappingId: pluginMappingId,
  });
  const result = await server.post("sites/deletePlugin", formData);
  return result.data as ResponseObject;
}

<script setup lang="ts">
import { computed } from "vue";

const props = defineProps<{
  color?: string;
  short?: boolean;
  dark?: boolean;
  light?: boolean;
}>();

const colorValue = computed(() => {
  if (props.color) return props.color;
  if (props.dark) return "#444";
  if (props.light) return "#e3e3e3";
  return "#e3e3e3";
});
</script>

<template>
  <svg :viewBox="short ? '0 0 20 12' : '0 0 50 12'">
    <g
      fill="none"
      fill-rule="evenodd"
    >
      <g :fill="colorValue">
        <g v-show="!short">
          <path d="M30.697.138h-.686a.708.708 0 0 0-.733.732v8.913h-2.717c-1.659 0-2.345 0-2.345-2.796V.87a.709.709 0 0 0-.733-.732h-.667c-.429 0-.752.315-.752.732v6.117c0 4.1 1.56 4.85 4.633 4.85 1.101 0 2.608 0 4.057-.205l.04-.008c.525-.132.635-.473.635-.736V.87a.708.708 0 0 0-.732-.732" />
          <path d="M39.253.166l-.062-.017c-.411-.086-1.525-.15-2.076-.15-2.55 0-3.085 1.349-3.085 2.479v8.527c0 .425.308.733.733.733h.686a.709.709 0 0 0 .733-.733V2.634c0-.272 0-.58 1.11-.58l1.688-.003c.199.028.4-.03.553-.161a.732.732 0 0 0 .256-.57V.89c0-.341-.201-.611-.536-.724" />
          <path d="M45.444 0c-2.024 0-3.174.399-3.843 1.335-.69.966-.83 2.466-.83 4.574 0 4.099.675 5.927 4.673 5.927.472 0 2.083-.01 3.032-.125a.728.728 0 0 0 .641-.725v-.451a.735.735 0 0 0-.752-.752h-2.92c-2.114 0-2.467-.47-2.522-3.345V5.48c.018-2.946.372-3.426 2.521-3.426 1.957 0 2.317.406 2.393 2.867h-3.282v2.073h4.457c.451 0 .988-.161.988-.93V5.49C49.937 1.283 48.873 0 45.444 0" />
        </g>
        <path
          :fill="colorValue"
          fill-rule="nonzero"
          d="M4.163 0l.001-.001c3.21 0 4.771 1.189 4.771 3.636v6.88c0 .515-.256.847-.76.987-.795.244-2.931.334-4.09.334C1.184 11.836 0 10.789 0 8.221c0-2.31 1.145-3.34 3.713-3.339h3.07v-1.13c0-1.222-.734-1.698-2.62-1.698h-2.43c-.446 0-.733-.287-.733-.732V.89c0-.374.194-.627.561-.731l.056-.012A20.5 20.5 0 0 1 4.163 0zm2.62 6.915H3.712c-1.195 0-1.54.293-1.54 1.307 0 1.038.092 1.58 2.09 1.58.42 0 1.655-.026 2.521-.146V6.915z"
        />
        <path
          fill="#74bb11"
          d="M19.934.496c-.11-.237-.346-.378-.629-.378h-.882c-.305 0-.543.128-.66.349L16.4 2.461l1.314 1.802 2.101-3.022c.27-.328.173-.63.12-.745"
        />
        <path
          fill="#009cd9"
          d="M13.03.443l-.004-.004a.783.783 0 0 0-.66-.321h-.863a.674.674 0 0 0-.618.35c-.12.228-.098.499.068.747l3.14 4.36-3.622 5.063c-.157.237-.178.503-.056.73.122.225.361.36.617.35h.882a.717.717 0 0 0 .636-.36l4.172-5.761L13.03.443z"
        />
        <path
          fill="#eb2084"
          d="M20.371 10.698l-2.687-3.7-1.296 1.794 2.11 2.822.085.038a.774.774 0 0 0 .31.067h.863a.686.686 0 0 0 .601-.32.705.705 0 0 0 .014-.701"
        />
      </g>
    </g>
  </svg>
</template>

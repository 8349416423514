<script setup lang="ts">
import type { ComboboxContentEmits, ComboboxContentProps } from "radix-vue";
import { ComboboxContent, useForwardPropsEmits } from "radix-vue";

const props = withDefaults(defineProps<ComboboxContentProps>(), {
  dismissable: false,
});
const emits = defineEmits<ComboboxContentEmits>();

const forwarded = useForwardPropsEmits(props, emits);
</script>

<template>
  <ComboboxContent v-bind="forwarded" class="overflow-y-auto overflow-x-hidden sm:max-h-96">
    <div role="presentation">
      <slot />
    </div>
  </ComboboxContent>
</template>

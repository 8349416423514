<script setup lang="ts">
import { reactiveOmit } from "@vueuse/core";
import { CommandItem, type CommandItemProps } from "ls/components/command";
import { withoutTrailingSlash } from "ufo";
import { type RouteLocationRaw, RouterLink } from "vue-router";

const props = defineProps<CommandItemProps & {
  name: string;
  path: string;
  href?: RouteLocationRaw;
}>();

const omitProps = reactiveOmit(props, ["name", "path", "href"]);
</script>

<script lang="ts">
function handleClick(event: MouseEvent) {
  // stop bubbling click event if trying to open in a new tab
  // in this case @select event won't be triggered on CommandItem
  if (event.ctrlKey || event.metaKey || event.button === 1 /* wheel click */) {
    event.stopPropagation();
  }
}
</script>

<template>
  <CommandItem class="group/command" v-bind="omitProps">
    <component
      :is="href ? RouterLink : 'div'"
      :to="href"
      class="flex min-w-0 flex-1 flex-row items-center gap-2 px-2 py-1"
      @click="handleClick"
    >
      <slot name="icon" />
      <div class="overflow-hidden">
        <p class="truncate">
          {{ name }}
        </p>
        <div v-if="path && path.trim() !== '/'" class="truncate text-xs text-slate-500">
          {{ withoutTrailingSlash(path) }}
        </div>
      </div>
      <slot />
    </component>
  </CommandItem>
</template>

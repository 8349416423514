import type { RouteLocationNormalizedLoaded, RouteLocationRaw, RouteRecordNormalized } from "vue-router";

import type { AxShareConfigModel } from "@shared/models";
import { isRelativeUrl, removeDuplicateForwardSlashes } from "../lib";
import { resolveApiBaseUrl } from "./api";
import { isDesktopLocal } from "@/common/environment";
import { testSameOrigin } from "@/common/lib/browser";

export const redirectParamName = "redirect";
export const authParamName = "auth";
export const targetIdParamName = "targetId";

export interface RouterNavigationLink {
  title: string;
  to: RouteLocationRaw;
}

interface MakeUrlOptions {
  relative?: boolean;
  baseUrl?: string;
}

const defaultOptions: MakeUrlOptions = {
  relative: false,
  baseUrl: undefined,
};

export const wellKnownRoutes = {
  home: {
    path: "/",
    name: "home",
  },
  uploadFromSketch: {
    path: "/upload-from-sketch",
    name: "uploadfromsketch",
  },
  downloadingUpdatesProgress: {
    path: "/downloading-updates-progress",
    name: "downloadingupdatesprogress",
  },
  proxylogin: {
    path: "/proxy-login",
    name: "proxylogin",
    props: true,
  },
  login: {
    path: "/login",
    name: "login",
  },
};

function isRoute(to: RouteLocationRaw | RouteRecordNormalized | RouteLocationNormalizedLoaded): to is RouteLocationNormalizedLoaded {
  return (to as any).fullPath !== undefined;
}

export function makeUrl(bundleSuffix: string, to: RouteLocationRaw | RouteLocationNormalizedLoaded, options: MakeUrlOptions = defaultOptions) {
  const { relative, baseUrl } = options;
  const base = relative ? "/" : baseUrl || resolveApiBaseUrl();
  const path = typeof to === "string" ? to : (isRoute(to) ? to.fullPath : to.path) || to.path || "";
  const url = removeDuplicateForwardSlashes(`${base}/${bundleSuffix}/${path}`);
  return url;
}

export function makeWebUrl(to: RouteLocationRaw | RouteLocationNormalizedLoaded, options = defaultOptions) {
  const webSuffix = import.meta.env.BASE_URL || "";
  return makeUrl(webSuffix, to, options);
}

export function resolveRedirect(to: RouteLocationRaw | RouteLocationNormalizedLoaded): RouteLocationRaw | undefined {
  if (isDesktopLocal) {
    const desktopUrl = makeWebUrl(to);
    window.location.href = decodeURIComponent(desktopUrl);
    return;
  }

  return to as RouteLocationRaw;
}

export function resolveAfterAuthRedirect(to: RouteLocationNormalizedLoaded, config: AxShareConfigModel | null): RouteLocationRaw | undefined {
  const { query } = to;
  const asShareHostUrl = config?.AxShareHostSecureUrl || "";

  if (query !== undefined && query[redirectParamName]) {
    const { [redirectParamName]: redirect } = query;
    if (typeof redirect === "string") {
      const isAbsoluteUrl = !isRelativeUrl(redirect);
      if (isDesktopLocal || isAbsoluteUrl) {
        let isSameOrigin = testSameOrigin(redirect, asShareHostUrl);

        // If user is on Account Server right now, and he is authenticated
        // then redirect him to "auth code generator" page
        if (!isSameOrigin && (!config?.AccountServiceSecureUrl || config.AccountServiceSecureUrl === asShareHostUrl)) {
          const authUrl = new URL("/user/AxureAuthRedirect", asShareHostUrl);
          authUrl.searchParams.append(redirectParamName, redirect);

          window.location.href = authUrl.href;
          return;
        }

        if (!isSameOrigin) {
          if (config) {
            if (config.AxSitesPrototypeDomain) {
              // Allow redirects to AxSitesPrototypeDomain
              try {
                const redirectHost = new URL(redirect).host;
                isSameOrigin = redirectHost.endsWith(config.AxSitesPrototypeDomain);
              } catch {
                isSameOrigin = false;
              }
            }

            if (!isSameOrigin && config.AxShareHostSecureUrl !== config.AxShareClientUrl) {
              // Allow redirects to server side URLs
              isSameOrigin = testSameOrigin(redirect, config.AxShareHostSecureUrl);
            }
          }

          if (!isSameOrigin) {
            return wellKnownRoutes.home;
          }
        }

        window.location.href = redirect + to.hash;
        return;
      }
      return { path: redirect, hash: to.hash };
    }
  }
  const resolved = resolveRedirect(to);
  if (!resolved) {
    return;
  }

  return wellKnownRoutes.home;
}

import type { RefOrGetter } from "ls/common/types";
import { useQuery, useQueryClient } from "@tanstack/vue-query";
import { STALE } from "ls/queries/staleTime";
import { computed, toValue } from "vue";
import { queries } from "./queries";

export function useOrganization(organizationId: RefOrGetter<string>) {
  const queryClient = useQueryClient();
  const options = computed(() => {
    return {
      ...queries.info(toValue(organizationId)),
      placeholderData: () => {
        const queryKey = queries.list().queryKey;
        return queryClient.getQueryData(queryKey)?.find(d => d.Id === toValue(organizationId));
      },
      enabled: computed(() => toValue(organizationId) !== "00000000-0000-0000-0000-000000000000"),
    };
  });
  const query = useQuery(options);
  return query.data;
}

export function useOrganizationFromList(organizationId: RefOrGetter<string>) {
  const options = computed(() => {
    return {
      ...queries.list(),
      staleTime: STALE.INFINITY,
    };
  });

  const query = useQuery(options);
  const organization = computed(() => query.data.value?.find(org => org.Id === toValue(organizationId)));

  return organization;
}

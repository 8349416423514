import type { KeyboardShortcut } from "./keyboardShortcuts";
import { useEventListener } from "@vueuse/core";

export type UseKeyboardShortcutOptions = AddEventListenerOptions;

export function useKeyboardShortcut(shortcut: KeyboardShortcut, handler: (e: KeyboardEvent) => unknown, options: UseKeyboardShortcutOptions = {}) {
  return useEventListener("keydown", e => {
    if (shortcut.isPressed(e)) {
      handler(e);
    }
  }, options);
}

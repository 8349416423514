import type { SortingFn } from "@tanstack/vue-table";
import { createNewSortInstance } from "fast-sort";
import { toSafeString } from "./string";

export const naturalSortCompare = new Intl.Collator(undefined, { numeric: true, sensitivity: "base" }).compare;

export function naturalSortComparer<T>(getter: (item: T) => string) {
  return (a: T, b: T) => naturalSortCompare(getter(a), getter(b));
}

// I don't know why but without explicit type VS code for some reason shows "cannot be named" error here
// type-check is correct though. Could be VS Code or Vue extension bug?
export const naturalSort: ReturnType<typeof createNewSortInstance> = createNewSortInstance({
  comparer: naturalSortCompare,
});

export const naturalTableSort: SortingFn<any> = (a, b, column) => {
  const s1 = toSafeString(a.getValue(column));
  const s2 = toSafeString(b.getValue(column));
  return naturalSortCompare(s1, s2);
};

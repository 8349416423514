<script setup lang="ts">
import type { ShortcutJs } from "@/generated/models";
import { CommandGroup, CommandLoading } from "ls/components/command";
import CommandEmpty from "ls/components/command/CommandEmpty.vue";
import { useCommandContext } from "ls/components/command/context";
import { toFileOverview } from "ls/router/builders";
import { computed, ref, watch } from "vue";
import { useRouter } from "vue-router";
import FileIcon from "../files/FileIcon.vue";
import { useRecents } from "../recents/useRecents";
import CommandShortcutEnter from "./CommandShortcutEnter.vue";
import SearchItem from "./SearchItem.vue";

const emit = defineEmits<{
  select: [];
}>();
const recents = useRecents();

const { searchTerm } = useCommandContext();
const filteredRecents = computed(() => {
  if (!recents.value) return undefined;
  if (!searchTerm.value) return recents.value;

  const search = searchTerm.value.toLowerCase();
  return recents.value.filter(r => r.Shortcut.Name.toLowerCase().includes(search));
});

// key and remount all items due to bug in radix-vue item collection tracking
// issue is fixed in radix-vue v2 and this workaround can be removed after migration is done
const itemsKey = ref(0);
watch(recents, () => {
  itemsKey.value++;
});

const noResults = computed(() => {
  const totalRecents = filteredRecents.value?.length;
  return totalRecents === 0;
});

const router = useRouter();
async function goToFile(file: ShortcutJs) {
  emit("select");
  await router.push(toFileOverview(file));
}
</script>

<template>
  <CommandLoading v-if="!recents" />
  <div :key="itemsKey">
    <CommandGroup heading="Recents">
      <SearchItem
        v-for="recent in filteredRecents"
        :key="recent.Shortcut.Shortcut"
        :value="recent"
        :name="recent.Shortcut.Name"
        :path="recent.Path"
        :href="toFileOverview(recent.Shortcut)"
        @select="goToFile(recent.Shortcut)"
      >
        <template #icon>
          <FileIcon class="size-4 shrink-0" :file="recent.Shortcut" />
        </template>
        <CommandShortcutEnter />
      </SearchItem>
    </CommandGroup>
  </div>
  <CommandEmpty v-if="noResults">
    Nothing to show here
  </CommandEmpty>
</template>

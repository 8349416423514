import { queryOptions } from "@tanstack/vue-query";
import { getUserInfo } from "ls/api/cloud/user";
import { STALE } from "ls/queries/staleTime";

export const queries = {
  info: () =>
    queryOptions({
      queryKey: ["current-user", "info"] as const,
      queryFn: getUserInfo,
      staleTime: STALE.INFINITY,
    }),
};

export const shareLinkAccess = {
  default: {
    type: "default",
    displayText: "Default",
    description: "Share link access is controlled separately for each project",
  },
  limited: {
    type: "limited",
    displayText: "Limited",
    description: "Share links can only be accessed by members of this workspace",
  },
} as const;

export type ShareLinkAccessType = keyof typeof shareLinkAccess;

export function toCloudUserViewOnly(type: ShareLinkAccessType): boolean {
  return type === "limited";
}

export function fromCloudUserViewOnly(userViewOnly: boolean): ShareLinkAccessType {
  return userViewOnly ? "limited" : "default";
}

import type { AxShareConfigModel, ExtendedSsoResponse } from "@/generated/models";
import type { RequestOptions } from "../common/server";
import { server } from "./server";

export async function getConfig({ signal }: RequestOptions = {}) {
  const response = await server.get("user/getConfig", { signal });
  return response.data as AxShareConfigModel;
}

export async function getUserInfo({ signal }: RequestOptions = {}) {
  const response = await server.get("user/getUserInfo", { signal });
  return response.data as ExtendedSsoResponse;
}

import type { RefOrGetter } from "ls/common/types";
import { type OrganizationJs, UserType } from "@/generated/models";
import { computed, toValue } from "vue";
import { useOrganization } from "./useOrganization";

export function useOrganizationAccessLevel(organizationId: RefOrGetter<string>) {
  const organization = useOrganization(organizationId);
  return useAccessLevelFromOrganization(organization);
}

export function useAccessLevelFromOrganization(organization: RefOrGetter<OrganizationJs | undefined>) {
  const accessLevel = computed(() => toValue(organization)?.CurrentUserAccessLevel);

  const isSuperAdmin = computed(() => accessLevel.value === UserType.SubInstanceSuperAdmin);
  const isTechnicalAdmin = computed(() => accessLevel.value === UserType.SubInstanceTechnicalAdmin);
  const isAdmin = computed(() => {
    const currentUserAccessLevel = accessLevel.value;
    return (
      currentUserAccessLevel === UserType.SubInstanceSuperAdmin
      || currentUserAccessLevel === UserType.SubInstanceTechnicalAdmin
      || currentUserAccessLevel === UserType.SubInstanceAdmin
    );
  });
  const isMember = computed(() => accessLevel.value === UserType.SubInstanceMember);
  const isGuest = computed(() => accessLevel.value === UserType.SubInstanceGuest);

  return {
    accessLevel,
    isSuperAdmin,
    isTechnicalAdmin,
    isAdmin,
    isMember,
    isGuest,
  };
}

<script setup lang="ts">
import type { ComboboxEmptyProps } from "radix-vue";
import { ComboboxEmpty } from "radix-vue";
import Text from "../Text.vue";

const props = defineProps<ComboboxEmptyProps>();
</script>

<template>
  <ComboboxEmpty
    v-bind="props"
    as-child
  >
    <Text class="py-6 text-center">
      <slot />
    </Text>
  </ComboboxEmpty>
</template>

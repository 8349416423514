import type { RefOrGetter } from "ls/common/types";
import { useQuery } from "@tanstack/vue-query";
import { computed } from "vue";
import { queries } from "./queries";
import { useFuzzySearch } from "./useFuzzySearch";

export interface UseSearchOptions {
  limit?: number;
  excludeViewOnly?: boolean;
  excludeArchived?: boolean;
}

export function useSearch(searchTerm: RefOrGetter<string>, options: UseSearchOptions = {}) {
  const { data, isPending } = useQuery(queries.search());

  const allWorkspaces = computed(() => data.value?.Filesystems ?? []);
  const allFolders = computed(() => data.value?.Folders ?? []);
  const allFiles = computed(() => data.value?.Shortcuts ?? []);

  const filteredWorkspaces = computed(() =>
    allWorkspaces.value
      .filter(f => !(options.excludeViewOnly && f.Filesystem.ViewerFs))
      .filter(f => !(options.excludeArchived && f.Filesystem.IsArchived)),
  );

  const filteredFolders = computed(() =>
    allFolders.value
      .filter(f => !(options.excludeViewOnly && f.IsViewer))
      .filter(f => !(options.excludeArchived && f.IsArchived)),
  );

  const filteredFiles = computed(() =>
    allFiles.value
      .filter(f => !(options.excludeViewOnly && f.Shortcut.InFsViewerFolder))
      .filter(f => !(options.excludeArchived && f.IsArchived)),
  );

  const workspaces = useFuzzySearch(filteredWorkspaces, f => f.Filesystem.DisplayName, searchTerm, { limit: options.limit });
  const folders = useFuzzySearch(filteredFolders, f => f.Folder.FolderName, searchTerm, { limit: options.limit });
  const files = useFuzzySearch(filteredFiles, f => `${f.Shortcut.Name}|${f.Shortcut.Shortcut}`, searchTerm, { limit: options.limit });

  return {
    isPending,
    workspaces,
    folders,
    files,
  };
}

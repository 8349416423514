import type { AxShareConfigModel } from "@shared/models";

import { isDesktopLocal, isElectronBuild } from "@/common/environment";
import { delay } from "@/common/lib";

let apiBaseUrl = "";
let accountServiceUrl = "";
let apiCallsCounter: number = 0;

// slight delay after write operation to avoid next stale read
export const dualDbDelay = (ms?: number) => delay(ms ?? 50);

export function setApiBaseUrl(url: string) {
  apiBaseUrl = url;
}

export function setAccountServiceHost(url: string) {
  accountServiceUrl = url;
}

export function resolveApiBaseUrl(config?: AxShareConfigModel | null): string {
  if (config) {
    return config.MatchProtocolMode ? config.AxShareHostUrl : config.AxShareHostSecureUrl;
  }
  if (apiBaseUrl) return apiBaseUrl;

  let result: string | undefined;
  if (isDesktopLocal) {
    result = import.meta.env.VITE_APP_DESKTOP_AXSHARE_API_URL;
  } else if (isElectronBuild) {
    result = import.meta.env.VITE_APP_AXSHARE_ELECTRON_API_URL;
  } else {
    result = import.meta.env.VITE_APP_AXSHARE_API_URL;
  }

  return result || "";
}

export function resolveAccountServiceHost(config?: AxShareConfigModel | null): string {
  if (config) {
    return config.MatchProtocolMode ? config.AccountServiceUrl : config.AccountServiceSecureUrl;
  }
  if (accountServiceUrl) return accountServiceUrl;
  return import.meta.env.VITE_APP_AXSHARE_ACCOUNT_SERVICE_URL || "";
}

export function getApiCallId() {
  apiCallsCounter++;
  return apiCallsCounter.toString();
}

import type { RouteRecordRaw } from "vue-router";
import { wheneverOnceAsync } from "ls/composables/wheneverOnceAsync";
import { useRouter } from "vue-router";
import { useAxureCloudConfig } from "../state/useAxureCloudConfig";
import { domainPrefixParameterName, routeNames } from "./constants";

const enterpriseRoute: RouteRecordRaw = {
  path: "/asec",
  name: routeNames.enterprise,
  component: () => import("ls/features/enterprise/Enterprise.vue"),
  redirect: {
    name: routeNames.enterpriseCreate,
  },
  meta: {
    requiresAuth: false,
  },
  children: [
    {
      path: `create/:${domainPrefixParameterName}?`,
      name: routeNames.enterpriseCreate,
      component: () => import("ls/features/enterprise/EnterpriseCreate.vue"),
      props: true,
    },
    {
      path: `register/:${domainPrefixParameterName}`,
      name: routeNames.enterpriseRegister,
      component: () => import("ls/features/enterprise/EnterpriseRegister.vue"),
      props: true,
    },
  ],
};

export function useConfigureRoutes() {
  const router = useRouter();
  const config = useAxureCloudConfig();

  router.beforeEach(async to => {
    if (!to.path.startsWith(enterpriseRoute.path)) {
      return true;
    }

    if (router.hasRoute(routeNames.enterpriseCreate)) {
      return true;
    }

    // Make sure that config is loaded
    const configValue = await wheneverOnceAsync(() => config.value);

    if (configValue.SubInstancesSupported) {
      router.addRoute(enterpriseRoute);
      return to.fullPath;
    } else {
      return routeNames.notFound;
    }
  });
}

import { createInjectionState, whenever } from "@vueuse/core";
import { useContextOrThrow } from "ls/composables/useContextOrThrow";
import { useAxureCloudConfig } from "ls/state/useAxureCloudConfig";
import { useIsSubInstance } from "ls/state/useSubInstance";
import { computed } from "vue";
import { useRecaptchaContext as useVueRecaptchaContext, useRecaptchaProvider as useVueRecaptchaProvider } from "vue-recaptcha";

const [useProvideRecaptchaContext, useRecaptchaContextFn] = createInjectionState(() => {
  useVueRecaptchaProvider();
  const context = useVueRecaptchaContext();
  const config = useAxureCloudConfig();
  whenever(config, ({ GoogleReCaptchaSiteKey }) => {
    context.options.v2SiteKey = GoogleReCaptchaSiteKey;
  });

  const isSubInstance = useIsSubInstance();
  const useReCaptcha = computed(() => !isSubInstance.value && !!config.value?.GoogleReCaptchaSiteKey);

  return {
    useReCaptcha,
  };
});

export { useProvideRecaptchaContext };
export const useRecaptchaContext = useContextOrThrow(useRecaptchaContextFn);

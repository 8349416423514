import type { ShortcutJs } from "@/generated/models";
import { ShortcutState } from "@/common/fs/types";
import { ShortcutType } from "@/services/models/filesystem";

const clickthroughProjectTypes = [ShortcutType.Expo, ShortcutType.Handoff];
const rpProjectTypes = [ShortcutType.Rp, ShortcutType.Lib, ShortcutType.TeamLib, ShortcutType.TeamRp];
const teamProjectTypes = [ShortcutType.TeamLib, ShortcutType.TeamRp];

const MinRP7Build = 3100;
const MinRP8Build = 3200;
const MinRP9Build = 3600;
const MinRP10Build = 3800;
const MinRP11Build = 4100;

const MinRP9BuildWithThumbnails = 3629;
const MinRP9HgRevWithThumbnails = 17532;
const MinRP10HgRev = 21000;

export interface FileOverridesOptions {
  resourceDirVersion?: number;
  shortcutState?: string;
}
export function isRp6ProjectAndBelow(file: ShortcutJs, options: FileOverridesOptions = {}) {
  const { resourceDirVersion = file.ResourceDirVersion, shortcutState = file.ShortcutState } = options;
  if (shortcutState
    && (shortcutState === ShortcutState.Generating
      || shortcutState === ShortcutState.Empty
      || shortcutState === ShortcutState.GenerationFailed)) {
    return false;
  }
  if (!resourceDirVersion) return false;
  return resourceDirVersion < MinRP7Build;
}

export function isRp7Project(file: ShortcutJs, options: FileOverridesOptions = {}) {
  const { resourceDirVersion: revNumber = file.ResourceDirVersion } = options;
  if (!revNumber) return false;
  return revNumber < 10000 && revNumber >= MinRP7Build && revNumber < MinRP8Build;
}

export function isRp8Project(file: ShortcutJs, options: FileOverridesOptions = {}) {
  const { resourceDirVersion: revNumber = file.ResourceDirVersion } = options;
  if (!revNumber) return false;
  return revNumber < 10000 && revNumber >= MinRP8Build && revNumber < MinRP9Build;
}

export function isRp9Project(file: ShortcutJs, options: FileOverridesOptions = {}) {
  const { resourceDirVersion: revNumber = file.ResourceDirVersion } = options;
  if (!revNumber) return false;
  return revNumber < 10000 && revNumber >= MinRP9Build && revNumber < MinRP10Build;
}

export function isRp9ProjectAndAbove(file: ShortcutJs, options: FileOverridesOptions = {}) {
  const { resourceDirVersion: revNumber = file.ResourceDirVersion } = options;
  if (!revNumber) return false;
  return revNumber >= MinRP9Build;
}

export function isRp9ProjectWithThumbnails(file: ShortcutJs, options: FileOverridesOptions = {}) {
  const { resourceDirVersion: revNumber = file.ResourceDirVersion } = options;
  if (!revNumber) return false;
  return ((revNumber >= MinRP9HgRevWithThumbnails && revNumber < MinRP10HgRev)
    || (revNumber < 10000 && revNumber >= MinRP9BuildWithThumbnails && revNumber < MinRP10Build));
}

export function isRp10Project(file: ShortcutJs, options: FileOverridesOptions = {}) {
  const { resourceDirVersion: revNumber = file.ResourceDirVersion } = options;
  if (!revNumber) return false;
  return (revNumber >= MinRP10HgRev || (revNumber < 10000 && revNumber >= MinRP10Build));
}

export function isRp11Project(file: ShortcutJs, options: FileOverridesOptions = {}) {
  const { resourceDirVersion: revNumber = file.ResourceDirVersion } = options;
  if (!revNumber) return false;
  return (revNumber < 10000 && revNumber >= MinRP11Build);
}

export function getRpVersion(file: ShortcutJs) {
  if (!isRpProject(file)) return undefined;
  if (isRp11Project(file)) return 11;
  if (isRp10Project(file)) return 10;
  if (isRp9Project(file)) return 9;
  if (isRp8Project(file)) return 8;
  if (isRp7Project(file)) return 7;
  if (isRp6ProjectAndBelow(file)) return 6;
  return undefined;
}

export function isThumbnailSupportedProject(file: ShortcutJs) {
  return (!!file.ShortcutState && file.ShortcutState !== ShortcutState.GenerationFailed)
    && (isClickthroughProjectType(file.ShortcutType)
      || isRp9ProjectWithThumbnails(file)
      || isRp10Project(file)
      || file.ShortcutState === ShortcutState.Empty);
}

export function projectWasNotGenerated(file: ShortcutJs) {
  return file.ShortcutState === ShortcutState.Generating || file.ShortcutState === ShortcutState.GenerationFailed;
}

export function isPublicInspectSupportedProject(file: ShortcutJs) {
  return isClickthroughProject(file) || (isRpProject(file) && isRp10Project(file));
}

export function isClickthroughProject(file: ShortcutJs) {
  return isClickthroughProjectType(file.ShortcutType);
}
export function isRpProject(file: ShortcutJs) {
  return isRpProjectType(file.ShortcutType);
}
export function isTeamProject(file: ShortcutJs) {
  return isTeamProjectType(file.ShortcutType);
}
export function isLibProject(file: ShortcutJs) {
  return isLibProjectType(file.ShortcutType);
}
export function isTeamLibProject(file: ShortcutJs) {
  return isTeamLibProjectType(file.ShortcutType);
}
export function isDiskoProject(file: ShortcutJs) {
  return isDiskoProjectType(file.ShortcutType);
}
export function isClickthroughProjectType(type: ShortcutType) {
  return clickthroughProjectTypes.includes(type);
}
export function isRpProjectType(type: ShortcutType) {
  return rpProjectTypes.includes(type);
}
export function isTeamProjectType(type: ShortcutType) {
  return teamProjectTypes.includes(type);
}
export function isLibProjectType(type: ShortcutType) {
  return type === ShortcutType.Lib;
}
export function isTeamLibProjectType(type: ShortcutType) {
  return type === ShortcutType.TeamLib;
}
export function isDiskoProjectType(type: ShortcutType) {
  return type === ShortcutType.Disko;
}

const RP_WITH_QUERY_SUPPORT = 3891;
export function isQuerySupportProject(file: ShortcutJs) {
  if (isClickthroughProjectType(file.ShortcutType)) return true;
  const revNumber = file.ResourceDirVersion;
  if (!revNumber) return false;
  return revNumber < 10000 && revNumber >= RP_WITH_QUERY_SUPPORT;
}

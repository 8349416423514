import { useQuery } from "@tanstack/vue-query";
import { sort } from "fast-sort";
import { computed } from "vue";
import { queries } from "./queries";

export function useRecents() {
  const query = useQuery(queries.recents());

  const recents = computed(() => {
    if (!query.data.value) return undefined;
    const items = sort(query.data.value.Items).desc(b => b.Date);
    return items;
  });

  return recents;
}

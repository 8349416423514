<script setup lang="ts">
import { UserAxureRpRole } from "@/generated/models";
import { useSubInstanceId } from "ls/state/useSubInstance";
import { computed } from "vue";
import { useCurrentUserInfo } from "../user/useAuth";
import InstanceSubscriptionExpired from "./InstanceSubscriptionExpired.vue";
import LicenseTrial from "./LicenseTrial.vue";

const user = useCurrentUserInfo();
const subInstanceId = useSubInstanceId();

const showLicenseBanner = computed(() => subInstanceId.value && user.value && user.value.axureRpRole !== UserAxureRpRole.Viewer);
</script>

<template>
  <template v-if="showLicenseBanner">
    <InstanceSubscriptionExpired />
    <LicenseTrial />
  </template>
</template>
